.dynamic-notify-wrapper {
  position: absolute;
  height: 47px;
  bottom: 48px;
  left: 50%;
  transform: translate(-50%, 0px);
  background: #343633;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
}

.dynamic-notify-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  /* margin-left: 9px; */
}
