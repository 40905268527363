.SDK-pane {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.fullscreen .SDK-pane {
  display: none;
}

.SDK-wrapper {
  width: 100% !important;
  height: 100%;
}

.SDK-wrapper .sub-header {
  border-top: 1px solid #eef1f4;
  border-bottom: 1px solid #eef1f4;
  position: relative;
  z-index: 1;
}

.SDK-wrapper .tab-wrapper .button {
  background-color: white;
  border: none;
  width: 130px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: normal;
  color: #35538a;
}
.SDK-wrapper .tab-wrapper .button.left {
  margin-left: 16px;
}

.SDK-wrapper .tab-wrapper .button.activated {
  background-color: #f4f7fe !important;
  font-weight: 600;
}

.SDK-wrapper .tab-wrapper .button:hover {
  color: #1a3870;
  background-color: #fbfbfb;
}

.sdk_editor {
  box-sizing: border-box;
  margin-bottom: 1rem;
  margin-right: 12px;
  margin-top: 13px;
  background-color: #f4f7fe;
}

.sdk-editor-container {
  background-color: #f4f7fe;
  margin-top: 18px;
}

.sdk-editor-container .ace_gutter {
  background-color: #f4f7fe;
}

.sdk-wrapper {
  width: 100% !important;
  height: 100%;
}

.sdk-pane {
  height: 17%;
  display: flex;
  flex-direction: row;
}
.tablelist {
  position: relative;
  padding-top: 10px;
  border-top: 1px solid #eef1f4;
  margin-left: 1rem;
  margin-right: 12px;
  height: 74%;
  top: 50px;
}
.table_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.table_item_heading {
  width: 95%;
  height: 37px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.table_item_heading div > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  margin-left: 7.5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table_item_heading div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.table_item {
  box-sizing: border-box;
  width: 33.3%;
  height: 314px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  border-right: 1px solid #eef1f4;
  border-bottom: 1px solid transparent;
  margin-top: 1rem;
}

.table_item .hr-phy,
.hr_cmn,
.hr_inf,
.hr_rec,
.hr_tbl,
.hr_pse {
  width: 100%;
  color: transparent;
  background-color: transparent;
  border-bottom: 1px solid transparent;
}
.table_item:hover .hr_phy {
  border-top: 1px solid #3f74f6;
  border-bottom: 1px solid #3f74f6;
}
.table_item:hover .hr_cmn {
  border-top: 1px solid #f6813f !important;
  border-bottom: 1px solid #f6813f !important;
}
.table_item:hover .hr_inf {
  border-top: 1px solid #a53ff6 !important;
  border-bottom: 1px solid #a53ff6 !important;
}
.table_item:hover .hr_rec {
  border-top: 1px solid #009ba5 !important;

  border-bottom: 1px solid #009ba5 !important;
}

.table_item:hover .hr_tbl {
  border-top: 1px solid #414d77 !important;
  border-bottom: 1px solid #414d77 !important;
}
.table_item:hover .hr_pse {
  border-top: 1px solid #f62f47 !important;
  border-bottom: 1px solid #f62f47 !important;
}

.table_item_body {
  width: 99%;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.table_item_body_wrapper:hover .table_item_body {
  border-bottom: 1px solid #eef1f4;
}
.table_item_body {
  border-bottom: 1px solid transparent;
}

.table_item_body > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 33px;
  color: #414d77;
  margin: 0%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin: 0px 12px;
  text-transform: uppercase;
}

.table_item_body:hover > .table_item_drop {
  display: block;
}
.table_item_drop {
  width: 18px;
  margin-right: 0.8rem;
  display: none;
}

.table_item_body_wrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.overflow::-webkit-scrollbar,
.table_item_body_wrapper::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.overflow::-webkit-scrollbar-track,
.table_item_body_wrapper::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.overflow:hover::-webkit-scrollbar-thumb,
.table_item_body_wrapper:hover::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}

.overflow::-webkit-scrollbar-thumb,
.table_item_body_wrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
  border-radius: 10px;
}

.drop_up {
  transform: rotateZ(180deg);
  margin-right: 1rem;
}

.hov_phy:hover {
  background-color: #f4f7fe;
}
.hov_cmn:hover {
  background-color: #fff3ec;
}
.hov_inf:hover {
  background-color: #fcf8ff;
}
.hov_rec:hover {
  background-color: #f2feff;
}
.hov_tbl:hover {
  background-color: #f5f5f5;
}
.hov_pse:hover {
  background-color: #fff2f4;
}

.fill_phy path {
  stroke: #3f74f6;
  fill: #3f74f6;
}
.fill_cmn path {
  stroke: #f6813f;
  fill: #f6813f;
}
.fill_inf path {
  stroke: #a53ff6;
  fill: #a53ff6;
}
.fill_rec path {
  stroke: #414d77;
  fill: #414d77;
}
.fill_tbl path {
  stroke: #009ba5;
  fill: #009ba5;
}
.fill_pse path {
  stroke: #f62f47;
  fill: #f62f47;
}
.sdk-pane:hover .copy_btn {
  opacity: 1;
}

.copy_btn {
  height: 32px;
  width: 32px;
  background-color: white;
  position: absolute;
  right: 26px;
  top: 58px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;
}

.copy_btn:active {
  height: 30px;
  width: 30px;
}
.copy_btn:active .copy_svg {
  width: 16px;
}

.copy_svg {
  display: block;
  margin: auto;
  height: 100%;
}
.overflow {
  overflow-y: auto;
}
.split-sdk {
  height: 88%;
}
.gutter-vertical {
  top: 44px;
  z-index: 10;
  /* border-top: 2px transparent; */
  /* border-bottom: solid 2px rgba(54, 92, 111, 0.06); */
  border-right: none;
  border-left: left;
  cursor: url(../../assets/icons/togglescreen.svg), auto;
}
.gutter-vertical:hover {
  opacity: 1;
  border-left: none;
  /* border-top: solid 2px lightgray; */
}

.gutter-vertical {
  touch-action: none;
}

.gutter {
  touch-action: none;
}

.del_cursor {
  display: none !important;
}

.highlightText {
  color: #5200ff;
  font-weight: 600;
}
