.tuning-pane {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.fullscreen .tuning-pane {
  display: none;
}

.optimise-wrapper {
  width: 75% !important;
  height: 100%;
  border-right: 1px solid #eef1f4;
}

.optimise-box {
  border-top: 1px solid #eef1f4;
  overflow-y: scroll;
  height: 95%;
}

.optimise-box::-webkit-scrollbar {
  height: 6px;
  width: 5px;
}

.anti-pattern-ody::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.optimise-box:hover::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}

.optimise-box::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
  border-radius: 10px;
}

.anti-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.5%;
}

.optimise-wrapper .sub-header {
  display: flex;
  align-items: center;
  padding-left: 32px;
  background: #edfbfc !important;
}
.optimise-wrapper .sub-header-default {
  height: 32px;
  width: 100%;
  border-bottom: 1px solid #eef1f4;
}
.pattern-line {
  color: #744a06;
  font-family: "Inter";
  font-style: normal;

  font-size: 12px;
  line-height: 20px;
  margin-left: 11px;
}
.anti-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #744a06;
  text-align: center;
  margin: 15px 0px;
}

.anti-para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  text-align: center;
  margin: 15px 0px;
}

.anti-img {
  margin: 15px 0px;
}

.suggest-btn {
  margin: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  display: flex;
}

.suggest-btn button {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border: 1px solid rgba(63, 116, 246, 0.3);
  border-radius: 6px;
  margin: 2.5px;
  color: #3f74f6;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 7px;
  cursor: pointer;
}

.hr {
  width: 12px;
  color: #bdbdbd;
  background-color: #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
}

.highlight > pre {
  padding: 12px 9px;
  border-radius: 8px;
  margin-top: 10px;
  display: block;
}
