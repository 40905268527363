.try-free-instruction {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  display: flex;
}

.try-free-instruction-wrapper {
  height: 38px;
  border: solid 1px #eef1f4;
  border-radius: 3.5px;
  background-color: white;
  display: flex;
}

.try-free-instruction-wrapper .instruction-left-bar {
  width: 4px;
  background-color: #3f74f6;
}

.try-free-instruction-wrapper .instruction-text {
  padding: 5px;
  margin-left: 1px;
}

.try-free-instruction .instruction-text {
  font-family: "Inter";
  font-size: 11px;
  font-weight: 400;
  color: #0c4284;
}

.try-free-instruction .instruction-text .instruction-content-bold {
  font-weight: 700;
}

.try-free-instruction .try-free-button,
.userinfo-bar .login-button {
  line-height: 38px;
  width: 80px;
  height: 38px;
  margin: auto 0px auto 10px;
}

.userinfo-bar .login-button {
  margin-right: 10px;
}

.userinfo-bar {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  height: 62px;
  z-index: 2;
}

.search-box {
  transition: width 1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  background: #ffffff;
  border-radius: 20px;
  width: 43px;
  height: 32px;
  margin-right: 1rem;
}

.search-box:hover,
.search-box:focus-within {
  width: 300px;
}

.search-box:hover input,
.search-box:focus-within input,
.search-box:hover .arrow-down,
.search-box:focus-within .arrow-down,
.search-box:hover .arrow-up,
.search-box:focus-within .arrow-up,
.search-box:hover .close-search,
.search-box:focus-within .close-search {
  display: flex;
}

.search-box:hover .search-suggest,
.search-box:focus-within .search-suggest {
  display: block;
}

.search-box input {
  margin-left: 0.5rem;
  outline: none;
  border: none;
  width: 100%;
  display: none;
}

.arrow-down {
  display: none;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: 1rem;
  border-radius: 50%;
  border: 0px;
  background-color: transparent;
  padding: 0px;
}
.arrow-down svg,
.arrow-up svg {
  width: 7px !important;
}
.arrow-up {
  margin-right: 1rem;
  transform: rotate(-180deg);
  display: none;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 0px;
  background-color: transparent;
  padding: 0px;
}
.arrow-up:hover,
.arrow-down:hover {
  background-color: #f5f5f5;
}

.close-search {
  display: none;
  align-items: center;
  border: 0px;
  background-color: transparent;
  padding: 0px;
}
.search-box > svg {
  width: 24px;
  height: 12px;
}

.search-input {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #6e6e6e;
}

.search-suggest {
  display: none;
  position: absolute;
  width: 300px;
  top: 3.3rem;
  left: 0px;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(12, 66, 132, 0.2);
  border-radius: 20px;
  height: auto;
  padding: 12px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex div {
  background-color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #6e6e6e;
  border: 1px solid #6e6e6e;
  border-radius: 24px;
  padding: 6px 8px;
}
.flex div span,
.flex div {
  margin-left: 4px;
  cursor: pointer;
}

.mb {
  margin-bottom: 12px;
}

.search-hr {
  border-bottom: 1px solid rgba(141, 150, 178, 0.16);
}

.search-type-acticated {
  background: rgba(155, 46, 245, 0.1) !important;
  border: none !important;
  color: #9b2ef5 !important;
  font-weight: 500 !important;
  padding: 7px 9px !important;
}

.search-type-acticated circle {
  fill: #9b2ef5 !important;
  fill-opacity: 1;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 35px;
  color: #7e8fb1;
  margin: 1px 0px;
  padding: 0px 12px;
  cursor: default;
}

.flex-col p:hover {
  background: #f5f5f5;
  border-radius: 10px;
}
