.overlay.antipattern-mpdal {
  background: none;
  width: fit-content;
  margin: auto;
  height: fit-content;
  margin-left: 48px !important;
}
.popup.antipattern {
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 4px 4px 12px rgba(1, 39, 85, 0.1);
  border-radius: 4px;
  padding: 0px;
  width: 390px !important;
  height: 480px;
  transition: none;
}
.error .popup.antipattern {
  height: 300px;
}
.popup.antipattern .close {
  top: 9px;
  right: 9px;
}
.popup.popup.antipattern > .content {
  height: calc(100% - 40px);
}
.anti-pattern-body {
  height: 100%;
  font-family: "Inter";
  color: #002a75;
}
.anti-pattern-body .caption {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edfbfc;
}

.anti-pattern-body .anti-pattern-label-wrapper {
  height: 32px;
  margin-top: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f4f7fe;
}

.caption .pattern-icon {
  /* padding-top: 7px; */
  /* margin-right: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #744a06;
}

.caption .pattern-label {
  padding-left: 5px;
  padding-right: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  display: flex;
  align-items: center;
  color: #744a06;
}

.caption .pattern-icon.error,
.caption .pattern-label.error {
  color: #9d0b23;
}

.anti-pattern-body .content {
  padding-left: 17px;
  overflow-y: auto;
}

.anti-pattern-body .sub-block {
  padding: 13px 0px 15px 0px;
  border-top: 1px solid #d3d3d3;
}

.anti-pattern-body .sub-block.first-sub-block {
  border-top: none;
  padding-bottom: 0px;
}
.anti-pattern-body .sub-block.second-sub-block {
  border-top: none;
}
.anti-pattern-body .sub-block .dropdown {
  background: #ffffff;
  border: 1px solid #f9a825;
  border-radius: 30px;
  width: 48px;
  height: 27px;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.anti-pattern-body .error .sub-block .dropdown {
  border-color: #f02849;
}
.anti-pattern-body .sub-block .dropdown span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  /* identical to box height, or 92% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #f9a825;
}
.anti-pattern-body .error .sub-block .dropdown span {
  color: #f02849;
}
.anti-pattern-body .sub-block .dropdown-content.hide {
  display: none;
}
.anti-pattern-body .sub-block .dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  width: 50px;
  border: 1px solid #f9a825;
  border-radius: 24px;
  padding: 8px 0;
  font-size: 12px;
  color: #8d96b2;
  font-weight: 500;
  z-index: 1;
}
.anti-pattern-body .error .sub-block .dropdown-content {
  border-color: #f02849;
}
.anti-pattern-body .sub-block .dropdown-content .item {
  cursor: pointer;
  padding: 8px;
}
.anti-pattern-body .sub-block .dropdown-content .item.selected {
  color: #f9a825;
}
.anti-pattern-body .error .sub-block .dropdown-content .item.selected {
  color: #f02849;
}
.anti-pattern-body .sub-block .dropdown-content .item:hover {
  border-radius: 20px;
  color: #444f64;
  background: #fef6e9;
}
.anti-pattern-body .error .sub-block .dropdown-content .item:hover {
  background: #feecef;
}
.anti-pattern-body .sub-caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  color: #744a06;
  padding-bottom: 8px;
}

.anti-pattern-body .error .sub-caption {
  color: #9d0b23;
}

.anti-pattern-body .pattern-preview {
  height: 150px;
}

.anti-pattern-body .pattern-text,
.anti-pattern-body .pattern-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  display: flex;
  align-items: center;
  color: #000000;
}

.anti-pattern-body .pattern-text {
  min-height: 20px;
}

.anti-pattern-body .pattern-description {
  min-height: 50px;
}
