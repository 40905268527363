.agree-check {
  display: flex;
  cursor: pointer;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #0c4284;
}

.agree-check input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 2px 6px 0px 0px;
  padding: 0px;
  width: 14px;
  min-width: 14px;
  height: 14px;
  border-radius: 3px;
  border: 2px solid #3f74f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.terms {
  font-size: 12px;
  color: blue;
}

.agree-check input[type="checkbox"]:checked::before {
  transform: scale(1) !important;
}

.agree-check input[type="checkbox"]::before {
  content: "";
  width: 6px;
  height: 6px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background: #3f74f6;
  border-radius: 50%;
}

.agree-check.required {
  color: #f02849 !important;
}

.agree-check.required input {
  border-color: #eba9b4;
}
