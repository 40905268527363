.tuning-pane {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.fullscreen .tuning-pane {
  display: none;
}

.tuning-wrapper {
  width: 100% !important;
  height: 100%;
}
