.fullscreen .main-toolbar {
  display: none;
}

.main-toolbar {
  margin-top: 94px;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  transition: 0.2s;
}

.main-toolbar-item-wrapper:hover {
  box-shadow: 2px 4px 6px rgba(12, 66, 132, 0.3);
  width: 242px;
}

.main-toolbar-item-wrapper {
  height: calc(100% - 94px) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f3f5f6;
  width: 242px;
}

.toolbar-width {
  width: 500px !important;
}
.main-toolbar:hover,
.main-toolbar-item-wrapper .up-wrapper,
.main-toolbar-item-wrapper .bottom-wrapper {
  width: 250px;
}
.main-toolbar:hover {
  transition-delay: 0.4s;
}

.main-toolbar-item {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.main-toolbar:hover,
.main-toolbar-item-wrapper,
.main-toolbar:hover .main-toolbar-item.top-item {
  border-top-right-radius: 12px;
}

.menu-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 48px;
  color: #0c4284;
  display: none;
  text-decoration: none;
}

.main-toolbar:hover .menu-text {
  display: inline;
}
.main-toolbar-item:hover {
  background: #ffffff;
}

.main-toolbar-item.activated {
  background: #0c4284;
}

.main-toolbar-item.activated .menu-text {
  color: #ffffff;
}

.setWidth {
  width: 500px;
}

.bottom-toolbar {
  z-index: 15;
  background: #f3f5f6;
  box-shadow: 2px 4px 6px rgba(12, 66, 132, 0.3);
  position: relative;
  top: calc(100% - 241px) !important;
  width: 200px;
  height: fit-content;
  transition: 0.2s;
}

.showToolbar {
  display: block;
}

.hide {
  display: none;
}

.color-blue path {
  stroke: #0c4284;
}
.color-white path {
  stroke: #ffffff;
}
