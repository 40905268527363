.account-setting-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.account-setting-container .account-setting-category {
  width: 235px;
  height: 100%;
  border-right: solid 1px #eef1f4;
}

.account-setting-container .account-setting-category-header,
.account-setting-container .account-setting-content-header {
  width: 235px;
  height: 28px;
  border-bottom: solid 1px #eef1f4;
}

.account-setting-container .account-setting-content-header {
  width: 100%;
}

.account-setting-container .account-setting-category-content {
  width: 100%;
  height: 100%;
}

.account-setting-container .account-setting-category-item {
  height: 48px;
  border-bottom: solid 1px #eef1f4;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #414d77;
  fill: #414d77;
  background: white;
}

.account-setting-container .account-setting-category-item.selected {
  color: #3f74f6;
  fill: #3f74f6;
  background: #f4f7fe;
}

.account-setting-container .account-setting-category-item-icon {
  width: 65px;
  display: flex;
  align-content: space-around;
}

.account-setting-container .account-setting-category-item-icon svg {
  margin: auto;
}

.account-setting-container .account-setting-content {
  width: 60%;
  overflow-y: scroll;
}

.account-setting-container .account-setting-content::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.account-setting-container .account-setting-content::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.account-setting-container
  .account-setting-content:hover::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}

.account-setting-container .account-setting-content::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
  border-radius: 10px;
}
.account-setting-content .account-setting-content-body {
  margin: 50px;
}

.plan-content .plan-paragrah {
  margin-top: 50px;
}
