.ace-parseql .ace_gutter {
  background-color: white;
  color: #bdbdbd;
}

.ace-parseql .ace_gutter-active-line.ace_gutter-cell {
  background-color: white;
  color: black;
  font-weight: 700;
}

.ace-parseql .ace_marker-layer .ace_active-line {
  background-color: #f0f0f0;
}

.ace_fold-widget {
  margin-right: -18px;
  margin-left: 6px;
}

/* .ace-parseql .ace_scrollbar-v,
.ace_scrollbar-v .ace_scrollbar-inner{
  width: 12px !important;
}

.ace-parseql .ace_scrollbar-h,
.ace_scrollbar-h .ace_scrollbar-inner {
  height: 12px !important;
} */

.ace_scrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.ace_scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
#sqlEditor:hover > .ace_scrollbar::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}

.ace_scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
  border-radius: 10px;
}

/* firefox */
:root {
  scrollbar-color: #aeaeae transparent !important;
}

.sql-pane {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.fullscreen .sql-pane,
.fullscreen .gutter.gutter-horizontal {
  display: none;
}

.sql-wrapper {
  width: 100% !important;
  height: 100%;
  position: relative;
}
.sql-wrapper:hover .copy_btn_sql_editor {
  opacity: 1;
}

.sql-wrapper .sub-header {
  border-top: solid 1px #eef1f4;
  border-bottom: 1px solid #eef1f4;
  position: relative;
  z-index: 1;
}

.sql-box {
  width: 100%;
  margin-top: 18px;
}

.sql-editor-container {
  /* border-top: solid 1px rgba(54, 92, 111, 0.06); */
  border-bottom: solid 1px rgba(54, 92, 111, 0.06);
}
.copy_btn_sql_editor {
  height: 32px;
  width: 32px;
  background-color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  border: 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0 8);
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;
}

.copy_btn_sql_editor:active {
  height: 30px;
  width: 30px;
}
.copy_btn_sql_editor:active .copy_svg {
  width: 16px;
}
.dialect-bar {
  margin-left: 10px;
  align-self: end;
  margin-bottom: 10px;
}

.ace_gutter-cell.antipattern {
  color: white;
}

.ace_gutter-cell {
  padding-left: 12px;
  padding-right: 18px !important;
}
.ace_gutter-layer {
  text-align: right;
}
.anti-pattern-wrapper {
  width: 20px;
  height: calc(100vh - 160px);
  position: absolute;
  left: 7px !important;
  z-index: 1;
}

.anti-pattern-mark {
  position: absolute;
  /* width: 100%; */
  height: 17px;
  width: 27px;
  margin: auto;
  display: flex;
  cursor: pointer;
  justify-content: center;
  padding-right: 3px;
  background: #fff;
}
.anti-pattern {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anti-pattern svg {
  margin: auto;
  pointer-events: none;
  box-sizing: border-box;
}

.anti-pattern .anti-pattern-count {
  pointer-events: none;
  color: #ffffff;
  position: absolute;

  width: 17px;
  height: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.ace_line {
  pointer-events: auto;
  border-bottom: 1px solid white;
}
.hover {
  background-color: transparent;
}
.hover:hover {
  background-color: #f4f7fe;
}

.ace_line:active,
.ace_line:focus {
  background-color: transparent;
}

.antipattern-bar {
  z-index: 6;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background: linear-gradient(
      0deg,
      rgba(240, 40, 73, 0.09),
      rgba(240, 40, 73, 0.09)
    ),
    #ffffff;
  /* error bar shadow */
  box-shadow: 0px -1px 2px rgba(1, 39, 85, 0.09);
  padding: 8px 10px;
}

.antipattern-bar.toggled {
  background: #ffffff;
}

.antipattern-bar .dropdown {
  cursor: pointer;
}

.antipattern-bar .antipattern-toggle {
  position: absolute;
  max-height: 25vh;
  width: 100%;
  bottom: 48px;
  left: 0px;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(0deg, #edfbfc, #edfbfc), #ffffff;
  scrollbar-color: #f57186;
  /* background-color: black; */
}

.antipattern-bar .no-item {
  padding: 10px;
  text-align: center;
  font-weight: 800px;
}

.antipattern-bar .antipattern-toggle::-webkit-scrollbar-thumb {
  background: #f57186;
}

.antipattern-bar .antipattern-toggle .pattern-lines-wrapper {
  display: flex;
  padding: 6px 22px 0px 22px;
  position: relative;
}

.antipattern-bar .antipattern-toggle .pattern-lines-wrapper::after {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0px;
  height: 0.5px;
  background-color: rgba(240, 40, 73, 0.5);
  width: calc(100% - 22px);
}

.antipattern-bar .antipattern-toggle .pattern-lines-wrapper.error {
  background: linear-gradient(0deg, #feecef, #feecef), #ffffff;
  /* error bar shadow */
  box-shadow: 0px -1px 2px rgba(1, 39, 85, 0.09);
}

.antipattern-bar .antipattern-toggle .pattern-lines-wrapper .line_number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  /* align-items: center; */
  color: rgba(0, 0, 0, 0.6);
  min-width: 46px;
  padding-right: 7px;
}

.antipattern-bar .antipattern-toggle .pattern-lines-wrapper .mark-container {
  min-width: 17px;
  height: 17px;
}

.antipattern-bar .antipattern-toggle .pattern-lines-wrapper .pattern-list {
  padding-left: 9px;
  overflow-x: hidden;
}

.antipattern-bar
  .antipattern-toggle
  .pattern-lines-wrapper
  .pattern-list
  .pattern-line {
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: #1a1a1a;
  padding-bottom: 5px;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}
.antipattern-bar
  .antipattern-toggle
  .pattern-lines-wrapper.error
  .pattern-list
  .pattern-line
  span {
  margin-right: 2px;
  font-weight: 600;
}
.antipattern-bar
  .antipattern-toggle
  .pattern-lines-wrapper.error
  .pattern-list
  .pattern-line
  span {
  color: #9d0b23;
}
.antipattern-bar
  .antipattern-toggle
  .pattern-lines-wrapper.antipattern
  .pattern-list
  .pattern-line
  span {
  color: #744a06;
}

.antipattern-bar
  .antipattern-toggle
  .pattern-lines-wrapper
  .pattern-list
  .pattern-line:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0.5px;
  left: 0px;
  height: 0.5px;
  background-color: rgba(240, 40, 73, 0.5);
  width: 100%;
}

.antipattern-bar .content {
  display: flex;
}

.antipattern-bar .content .error-wrapper {
  background: #ffffff;
  border: 1px solid rgba(240, 40, 73, 0.3);
  border-radius: 30px;
  width: 81px;
  height: 32px;
  margin-right: 7px;
  padding: 6px 7px;
  display: flex;
  cursor: pointer;
}

.antipattern-bar .content .error-wrapper.selected {
  background: #feecef;
}

.antipattern-bar .content .pattern-wrapper {
  background: #ffffff;
  border: 1px solid rgba(249, 168, 37, 0.3);
  border-radius: 30px;
  width: 131px;
  height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 7px;
  cursor: pointer;
}

.antipattern-bar .content .pattern-wrapper.selected {
  background: #feecef;
}

.antipattern-bar .content .pattern-description {
  padding-left: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  /* identical to box height, or 79% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  /* account menu text color */

  color: #414d77;
}

.sql-button-bar span {
  border: none;
}
.visualize-container {
  background-color: #0c4284;
  opacity: 1;
}
.parse-container {
  background-color: #0c4284;
  opacity: 1;
}

.sql-button-bar .toolbar-item {
  width: 36px;
}

.sql-button-bar .toolbar-item.left {
  margin-left: 8px;
}

.tuning-button {
  width: 126px;
  height: 48px;
  border: none;
  color: white;
  background-color: #0c4284;
  line-height: 48px;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 700;
  cursor: pointer;
}

.tuning-button[disabled] {
  opacity: 0.45;
  pointer-events: none;
  cursor: default;
}

.sdktext1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #0c4284;
  /* border-bottom: 1px solid #01C1D5 !important; */
}

.sdktext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #0c4284;
  border-bottom: 1px solid #01c1d5 !important;
  cursor: pointer;
}

.sdktext:hover {
  color: #01c1d5;
}
.sdk-align {
  /* justify-content: center; */
  align-items: center;
}

.tooltip {
  position: absolute;
  display: inline-block;
  padding: 5px 0px;
  width: 248px;
  z-index: 10;
  background: #edfbfc;
  transition: 0.3s;
  /* tables blue */

  border-left: 3px solid #01c1d5;
  border-radius: 8px;
  top: calc(100% - 124px);
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
}

.tooltip p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 16px;
  color: #0c4284;
  margin: 0%;
  /* margin-bottom: 7px; */
}
.tooltip > div {
  margin-left: 7px;
  margin-top: 5px;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 6%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: #edfbfc transparent transparent transparent;
}
.Anti-pattern > .body > span {
  position: relative;
  bottom: 8px;
}

.Anti-pattern table {
  padding-left: 45px;
}

.anti-pattern-mark text {
  font-size: 10px;
}
