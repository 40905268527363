.property-box {
  padding-left: 36px;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
}

.property-box.disabled {
  pointer-events: none;
  color: #747474;
}

.button-wrapper {
  height: 30px;
  margin: 6px 10px;
  display: inline-flex;
  justify-content: space-around;
}

.property-box li {
  list-style: none;
  margin-left: -60px;
  margin-top: 2px;
}

.property-box li label,
.property-box li input {
  cursor: pointer;
  padding-left: 10px;
}

.propery-radio-wrapper {
  display: flex;
  justify-content: space-around;
}

.input-wraper {
  display: flex;
  flex-direction: column;
}

.property-input {
  width: 80px;
  height: 28px;
  margin: 4px;
  border: 1px solid #dbdbdd;
  border-radius: 2px;
  background-color: #f5f5f5;
}

.disabled .property-input {
  background-color: #f5f5f5;
}

.propertybox {
  /* padding-left: 36px;
  padding-top: 20px; */
  width: 103px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
}
.property-box1 {
  /* padding-left: 36px;
  padding-top: 20px; */
  width: 116px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
}

.space {
  display: flex;
  margin-left: 10px;
}

.property-box.disabled {
  pointer-events: none;
  color: #747474;
}

.buttonwrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonwrapper:hover {
  border: 1px solid #c8e7eb;
  border-radius: 2px;
}

.buttonwrapper:active {
  border: 1px solid #c8e7eb;
  background-color: #e4fcff;
  border-radius: 2px;
}

.button-wrapper1 {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper1:hover {
  border-bottom: 1px solid #c7f6fb;
  border-top: 1px solid #c7f6fb;
  border-left: 1px solid #c7f6fb;
  border-radius: 2px;
}

.button-wrapper1:active,
.bg {
  border: 1px solid #c7f6fb;
  background-color: #e4fcff;
  border-radius: 2px;
}

.button-wrapper2 {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper2:hover {
  border-bottom: 1px solid #c7f6fb;
  border-top: 1px solid #c7f6fb;
  border-right: 1px solid #c7f6fb;
  border-radius: 2px;
}

.button-wrapper2:active,
.bg {
  border: 1px solid #c7f6fb;
  background-color: #e4fcff;
  border-radius: 2px;
}

.wrapper1 {
  width: 150px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper2 {
  width: 96px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main1-wrapper {
  height: 82px;
  width: 82%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.property-box li {
  list-style: none;
  margin-left: -60px;
  margin-top: 2px;
}

.property-box li label,
.property-box li input {
  cursor: pointer;
  padding-left: 10px;
}

.propery-radio-wrapper {
  display: flex;
  justify-content: space-around;
}

.input-wraper {
  display: flex;
  flex-direction: column;
}

.property-input {
  width: 80px;
  height: 28px;
  margin: 4px;
  border: 1px solid #dbdbdd;
  border-radius: 2px;
  background-color: #f5f5f5;
}

.disabled .property-input {
  background-color: #f5f5f5;
}
