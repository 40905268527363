.sample-button-wrapper {
  padding-left: 16px;
}
.sample-button-wrapper .button {
  background-color: white;
  border: none;
  width: 90px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: normal;
  color: #35538a;
}
.sample-button-wrapper .button.activated {
  background-color: #f4f7fe;
  font-weight: 600;
}
.sample-button-wrapper .button:hover {
  color: #1a3870;
  background-color: #fbfbfb;
}
