/* css for animation */
@keyframes fade-in {
  0% {
    opacity: 1;
    top: 0px;
  }

  100% {
    opacity: 0;
    top: 50px;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 1;
    top: 0px;
  }

  100% {
    opacity: 0;
    top: 50px;
  }
}

@-moz-keyframes fade-in {
  0% {
    opacity: 1;
    top: 0px;
  }

  100% {
    opacity: 0;
    top: 50px;
  }
}

@keyframes ready-fade-in {
  0% {
    opacity: 0;
    bottom: 50px;
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@-webkit-keyframes ready-fade-in {
  0% {
    opacity: 0;
    bottom: 50px;
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@-moz-keyframes ready-fade-in {
  0% {
    opacity: 0;
    bottom: 50px;
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

/************************/
.checkmark-btn {
  width: 126px;
  height: 48px;
  border: none;
  color: white;
  background-color: #0c4284;
  line-height: 48px;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 700;
  cursor: pointer;
}

.checkmark-btn[disabled] {
  opacity: 0.45;
  pointer-events: none;
  cursor: default;
}

.checkmark-btn .checkmark-btn-text {
  position: relative;
  animation: fade-in 0.2s infinite;
  -webkit-animation: fade-in 0.2s infinite;
  -moz-animation: fade-in 0.2s infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
}

.checkmark-btn .ready {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark-btn .ready.done {
  position: relative;
  animation: fade-in 0.2s infinite;
  -webkit-animation: fade-in 0.2s infinite;
  -moz-animation: fade-in 0.2s infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
}

.checkmark-btn .ready .ready-icon-container {
  width: 24px;
}

.checkmark-btn .ready .done-text {
  animation: ready-fade-in 0.2s infinite;
  -webkit-animation: ready-fade-in 0.2s infinite;
  -moz-animation: ready-fade-in 0.2s infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  margin-left: 10px;
}

.checkmark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
}

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-miterlimit: 10;
  fill: none;
  animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.3s forwards;
}
