.Message .ace_layer span,
.Antipattern .ace_layer span {
  color: #5200ff;
}

.joinData .ace_line:nth-child(1),
.filterData .ace_line:nth-child(1) {
  color: #5200ff !important;
  font-weight: 600 !important;
}
