.user-info {
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 62px;
  cursor: pointer;
}

.user-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: auto;
  background-color: rgb(247, 153, 169);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.dropdown-wrapper {
  position: absolute;
  right: 0px;
  top: 100%;
}

.dropdown-wrapper .userinfo-dropdown-pane {
  width: 168px;
  height: 154px;
  background-color: white;
  box-shadow: 0px 1px 7px rgba(12, 66, 132, 0.13);
  border-radius: 20px;
  padding: 25px;
}
.dropdown-wrapper .userinfo-dropdown-pane .userinfo-dropdown-pane-bottom,
.dropdown-wrapper .userinfo-dropdown-pane .userinfo-dropdown-pane-top {
  display: flex;
  flex-direction: column;
}

.dropdown-wrapper .userinfo-dropdown-pane .userinfo-dropdown-link {
  color: #8d96b2;
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.dropdown-wrapper .userinfo-dropdown-pane .userinfo-dropdown-link:hover {
  color: #01c1d5;
  background-color: #f4f7fe;
  border-radius: 10px;
}

.dropdown-wrapper .userinfo-dropdown-pane .split-line {
  border: solid 0.5px #e4e5e8;
  width: 144px;
  margin: auto;
}
