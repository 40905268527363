.main-wrapper {
  min-width: 1300px !important;
  overflow: hidden;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  background-color: rgba(54, 92, 111, 0.06);
  width: calc(100vw - 48px);
}

.fullscreen .header-wrapper {
  display: none;
}
