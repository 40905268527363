.tabbar-wrapper {
  max-width: 60.5%;
  min-width: 60%;
  height: 65px;
  display: flex;
  flex-direction: row;
  z-index: 3;
}

.tabbar-wrapper .tag-line {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #414d77;
  position: absolute;
  left: 2px;
  top: 9px;
}

.tabbar-list {
  display: flex;
  max-width: 100%;
  flex-shrink: 0;
  align-items: flex-end;
}

.tab-item {
  width: 135px;
  word-wrap: break-word;
  height: 30px;
  margin-right: 1px;
  padding: 4px 5px 4px 15px;
  box-shadow: 0 -4px 10px 0 rgba(54, 92, 111, 0.06);
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 31px;
  border: solid 1px #edeff1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  flex-shrink: 0;
  align-items: center;
}

.tab-item:hover,
.tab-item.active {
  background-color: #ffffff;
  border-color: #ffffff;
}

.tab-item.active {
  z-index: 10;
}

.tab-item.active:hover {
  cursor: text;
}

.tab-item.active.sample:hover {
  cursor: default;
}

.tab-item.renaming {
  padding: 3px 5px;
}

.tab-item input {
  height: 24px;
  margin-top: -1px;
  max-width: 100%;
  background-color: #f2f2f2;
  font-family: "Inter";
  color: #002a75;
  font-size: 14px;
  font-weight: 600;
}

.tab-item input:focus {
  outline-width: 0px;
  border-radius: 2px;
  border-color: #f2f2f2;
  border-style: inherit;
}

.tab-label {
  min-width: calc(100% - 15px);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  line-height: 18px;
  color: #414d77;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
  -webkit-text-size-adjust: none;
  text-size-adjust: 100%;
}
.hidden {
  display: none !important;
}
.tab-item:hover .tab-label,
.tab-item.active .tab-label {
  color: #002a75;
  font-size: 14px;
  font-weight: 600;
  text-size-adjust: 100%;
}

.tab-item .close-mark {
  fill: #595959;
  margin-right: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tab-item .close-mark svg {
  pointer-events: none;
}

.tab-new {
  width: 37px;
  height: 37px;
  margin-top: 26px;
  border-radius: 8px;
  background-color: #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
}

.tab-arrow {
  width: 70px;
  height: 30px;
  margin-right: 1px;
  padding: 4px 5px 4px 15px;
  box-shadow: 0 -4px 10px 0 rgba(54, 92, 111, 0.06);
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 31px;
  border: solid 1px #edeff1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.tablist-scroll {
  display: inline-flex;
  overflow: hidden;
  max-width: 99.5%;
  scroll-behavior: smooth;
  flex-shrink: 0;
}
