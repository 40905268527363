.survey-modal-body {
  display: flex;
  height: 100%;
  width: 100%;
}

/* .survey-modal-body .tuning-image {
    width: 100%;
    height:100%;
    min-width: 32rem;
    min-height: 48rem;

} */

.left-panel1 svg {
  width: 20rem;
  margin-bottom: 1rem;
}
.left-panel1 h1 {
  font-size: 55px;
  color: #0c4284;
  font-weight: bolder;
  margin: 80px 58px;
  text-align: center;
}
.survey-modal-body .left-panel1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border-radius: 3rem; */
  justify-content: space-between;
  align-items: center;
  width: 55%;
  background-color: rgba(1, 192, 213, 0.22);
}
.survey-modal-body .right-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Inter";
  position: relative;
}

/* .popup{
    height: 100%;
} */
.content1 {
  width: 100%;
  height: 100%;
}
.popup1 {
  margin: 0px;
  padding: 0px;
  border-radius: 23px;
  width: auto !important;
  position: relative;
  height: 100%;
}

.survey-modal-body .next-button {
  width: 64px;
  height: 48px;
  margin-top: 10px;
  background: #3f74f6;
  border-radius: 11px;
  border: none;
  cursor: pointer;
}

.survey-modal-body form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 48px;
}

.survey-modal-body .description,
.survey-modal-body .description-bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #414d77;
}

.survey-modal-body .description-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  color: #414d77;
  margin: 0px !important;
}
.survey-modal-body .description-bold {
  font-weight: 700;
}

.survey-modal-body input {
  width: 48px;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  border: 2px solid #3f74f655;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 16px;
  color: #3f74f6;
}

.survey-modal-body input:focus,
.survey-modal-body input:hover {
  border-color: #3f74f6;
}

.survey-modal-body .email {
  width: 260px;
  text-align: left;
  padding-left: 10px;
  margin-top: -20px;
  margin-bottom: -5px;
}

.survey-modal-body select.database-type {
  width: 148px;
  height: 48px;
  border: 2px solid #3f74f655;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 16px;
  padding-left: 10px;
  color: #3f74f6;
}

.survey-modal-body textarea {
  margin-right: 50px;
  resize: none;
  height: 85px;
  width: 70%;
  border: 2px solid #3f74f655 !important;
  border-radius: 6px;
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 10px;
  color: #3f74f6;
}

/* .textarea1{
    margin-right: 50px;
    resize: none;
    height: 60px !important;
    border: 1.6px solid #3F74F655 !important;
    border-radius: 6px;
    margin-top: 5px !important;
    margin-bottom: 16px;
    padding: 10px;
    color: #3F74F6;
} */

.survey-modal-body input.required,
.survey-modal-body .required input {
  border-color: #eba9b4;
}

.survey-modal-body input:focus.required {
  border-color: #f02849;
  outline: none;
}

.survey-modal-body .back-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: -25px;
  margin-bottom: 20px;
}

.survey-modal-body .back-button1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: -25px;
  margin-bottom: 16px;
}

.survey-modal-body .back-button1 .caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3f74f6;
  margin-left: 8px;
}

.survey-modal-body .back-button .caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3f74f6;
  margin-left: 8px;
}

.survey-modal-body .second-pane-options-wrapper {
  margin-top: 30px;
}

.survey-modal-body .second-pane-options-wrapper .option-selector {
  margin-top: 16px;
}

.survey-modal-body .submit-button {
  width: 180px;
  height: 48px;
  margin-top: 20px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.survey-modal-body .success-pane {
  width: 100%;
  height: 100%;
  background: #01c1d533;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-modal-body .success-pane .tuning-avata {
  width: 54px;
  height: 52px;
  margin: 30px auto 11px auto;
}

.survey-modal-body .success-pane .tuning-success-caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 43px;
  align-items: center;
  text-align: center;
  letter-spacing: -1px;
  color: #0c4284;
}

.survey-modal-body .tuning-success-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0c4284;
  width: 298px;
  margin-top: 24px;
}

.img-survey {
  margin-top: 24px;
}
.survey-modal-body .perfect-button {
  width: 118px;
  height: 48px;
  margin-top: 29px;
  margin-bottom: 47px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.agree-wrapper {
  display: inline;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin-top: 22px;
  color: #0c4284;
}

textarea.required {
  border-color: #eba9b4 !important;
}

select.required {
  border-color: #eba9b4 !important;
}
