.loader-component {
  position: absolute;
  display: flex;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loader-component::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loader-svg {
  position: absolute;
  display: flex;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
