.parse-pane {
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.fullscreen .parse-pane,
.fullscreen .gutter.gutter-horizontal {
  display: none;
}

.parse-wrapper {
  width: 100% !important;
  height: 100%;
}
.ace_editor {
  line-height: 21px !important;
}
.parse-wrapper .sub-header {
  border-top: 1px solid #eef1f4;
  border-bottom: 1px solid #eef1f4;
  position: relative;
  z-index: 1;
}

.parse-box {
  width: 100%;
  margin-top: 18px;
}

.ace-tm .ace_gutter {
  background-color: white;
  color: #bdbdbd;
}

.ace-tm .ace_gutter-active-line.ace_gutter-cell {
  background-color: white;
  color: black;
  font-weight: 700;
}

.ace-tm .ace_marker-layer .ace_active-line {
  background-color: #f0f0f0;
}

.parse-editor-container {
  border-top: solid 1px rgba(54, 92, 111, 0.06);
  border-bottom: solid 1px rgba(54, 92, 111, 0.06);
}

.parse-wrapper .tab-wrapper .button {
  background-color: white;
  border: none;
  width: 130px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: normal;
  color: #35538a;
}
.parse-wrapper .tab-wrapper .button.left {
  margin-left: 16px;
}

.parse-wrapper .tab-wrapper .button.activated {
  background-color: #f4f7fe !important;
  font-weight: 600;
}

.parse-wrapper .tab-wrapper .button:hover {
  color: #1a3870;
  background-color: #fbfbfb;
}

.diagram-box .parse-wrapper .float-toolbox {
  position: absolute;
  top: 49px;
  right: 17px;
  width: 32px;
  height: 32px;
}

.parse-wrapper .tablelist-container {
  color: black;
  overflow: auto;
  height: 100%;
}

.tablelist-container .tablelist-item-category {
  font-size: 18px;
  font-weight: 700;
  margin: 10px;
  padding-left: 0px;
}

.tablelist-item-category .category-node,
.tablelist-item-category .table-node {
  cursor: pointer;
}

.tablelist-item-category.collapsed .tablelist-item-table,
.tablelist-item-table.collapsed .tablelist-item-column {
  display: none;
}

.tablelist-container .tablelist-item-table {
  color: #0c4284;
  font-size: 16px;
  font-weight: 600;
  margin: 10px;
}

.tablelist-container .tablelist-item-column {
  font-size: 14px;
  font-weight: 300;
  margin: 4px 4px 4px 50px;
}

.parse-pane .parse-notify-box {
  position: absolute;
  left: 60px;
  top: 32px;
  z-index: 10;
}

.parse-pane .notify-indicator {
  width: 16px;
  height: 14px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 14px solid #f4f7fe;
}

.parse-notify-box.json .notify-indicator {
  transform: translateX(45px);
}

.parse-notify-box.xml .notify-indicator {
  transform: translateX(145px);
}

.parse-notify-box.tablelist .notify-indicator {
  transform: translateX(235px);
}

.parse-pane .parse-notify-wrapper {
  width: 287px;
  height: 186px;
  background: #f4f7fe;
  border: 1px solid #f4f7fe;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  text-align: center;
  color: #0c4284;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}

.parse-pane .parse-notify-caption {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

.parse-pane .parse-notify-body {
  font-weight: 400;
  font-size: 12.5px;
  line-height: 17px;
  width: 250px;
}

.parse-pane .parse-notify-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #ffffff;
  background: #3f74f6;
  border-radius: 6px;
  width: 250px;
  height: 40px;
  cursor: pointer;
}
#parseDataEditor > .ace_scrollbar {
  margin-right: 4px;
}
.ace_scrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.ace_scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
#parseDataEditor:hover > .ace_scrollbar::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}

.ace_scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
  border-radius: 10px;
}

/* firefox */
:root {
  scrollbar-color: #aeaeae transparent !important;
}
.ace_scrollbar {
  right: 2px;
}
