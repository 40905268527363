.container-pane {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 8px 0px #00000014;
  position: absolute;
  right: 0px;
  z-index: 1;
  border-radius: 12px 4px 12px 12px;
  color: #002a75;
  margin-top: 18px;
  height: fit-content;
  margin-right: 20px;
}

.container-pane1 {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 8px 0px #00000014;
  position: absolute;
  right: 0px;
  z-index: 1;
  border-radius: 12px 4px 12px 12px;
  color: #002a75;
  margin-top: 18px;
  height: 79%;
  margin-right: 20px;
}

.container-pane.disabled {
  pointer-events: none;
  color: #b6b6b6 !important;
}

.container-pane1.disabled {
  pointer-events: none;
  color: #b6b6b6 !important;
}

.container-wrapper {
  height: 100%;
  width: 390px;
  border-radius: 12px 4px 12px 12px;
}

.container-header {
  height: 62px;
  background-color: white;
  border-top-left-radius: 12px;
  display: inline-flex;
  padding-left: 19px;
}

.container-header-icon {
  margin: auto;
  cursor: default !important;
}

.container-title {
  width: 260px;
  height: 20px;
  margin: 22px 10px 22px 19px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.container-close-button {
  fill: #d2d2d2;
  margin: 20px 12px 20px 15px;
  pointer-events: initial !important;
  cursor: pointer;
}

.container-tab-wrapper {
  height: 32px;
  background-color: white;
  border-top: solid 1px #eef1f4;
}

.container-tab {
  flex: 1 1;
  text-align: center;
  line-height: 32px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: normal;
  color: #35538a;
  cursor: pointer;
}

.container-tab:hover {
  background-color: #fbfbfb;
  color: #1a3870;
}

.container-tab.activated {
  font-weight: 600;
  background-color: #f4f7fe;
}

.disabled .container-tab {
  color: #b6b6b6 !important;
}

.container-box {
  height: calc(100% - 96px);
  border-top: solid 1px #eef1f4;
  border-bottom-left-radius: 12px;
}
