.error-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10%;
}

.error-page button {
  background-color: #3f74f6;
  border-radius: 6px;
  color: #fff;
  padding: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.error-page p {
  margin: 3%;
  width: 60%;
}
