.API-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  margin-top: 12%;
}

.API-pane > h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #0c4284;
  width: 50%;
  text-align: center;
}

/* .API-pane > button{
    background: #3F74F6;
    border-radius: 6px;
    padding: 14px;
    gap: 13px;
    outline: none;
    border: none;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 7px;
} */

.api_wrap {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}
