@import url("https://fonts.googleapis.com/css?family=Lato:100,300,regular,700,900%7COpen+Sans:300%7CIndie+Flower:regular%7COswald:300,regular,700&#038;subset=latin%2Clatin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i");

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter.ttf") format("truetype");
}

@font-face {
  font-family: "Monospace";
  src: local("Monospace"),
    url("./assets/fonts/Monospace.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono"),
    url("./assets/fonts/RobotoMono.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  overflow: hidden;
}
*,
::after,
::before {
  box-sizing: border-box;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 100;
  display: flex;
  align-items: center;
}

.popup {
  margin: 20px auto;
  padding: 10px;
  background: #fff;
  border-radius: 23px;
  width: auto !important;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.popup .close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 25px;
  right: 30px;
  transition: all 200ms;
  font-size: 45px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  /* it should higher that overlay z-index 100 */
  z-index: 101;
  width: 11.5px;
  height: 11.5px;
}

.popup .close:hover {
  cursor: pointer;
  color: hsl(0, 0%, 55%);
}

.popup .content {
  height: 100%;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}

.bright-theme .header {
  background-color: rgba(54, 92, 111, 0.06);
  border: 0px solid rgba(54, 92, 111, 0.06);
}

.bright-theme .bar {
  background-color: white;
}

.bright-theme .sub-header {
  background-color: white;
}

.bright-theme .left-sidebar {
  background-color: rgba(54, 92, 111, 0.06);
}

.bright-theme .pane,
.bright-theme .wrapper {
  background-color: white;
}

.hidden {
  display: none !important;
}

.sub-header-container {
  margin: 0px;
  display: flex;
}

.sub-header {
  height: 32px;
  display: flex;
  flex-direction: row;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.sub-header.active {
  background-color: #00a1b0;
}

.sub-header-title {
  font-size: 16px;
  line-height: 24px;
  color: white;
}

.collapsed .sub-header span {
  margin-left: -11px;
}

.pane.collapsed,
.collapsed .pane {
  width: 24px !important;
  background-color: rgba(54, 92, 111, 0.06);
}

.small-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.large-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.btn-right {
  float: right;
}

.button {
  height: 30px;
  cursor: pointer;
}

.button:hover {
  color: white;
}

.div-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  background: #3f74f6;
  border-radius: 6px;
  cursor: pointer;
}

select {
  height: 30px;
}

.menu-icon {
  fill: #0c4284;
}

.menu-icon.activated {
  fill: #ffffff !important;
}

.toolbar-icon {
  fill: #8d96b2;
  stroke: #8d96b2;
}

.disabled svg {
  fill: #b9b9b9;
  stroke: #b9b9b9;
}

.overlay {
  opacity: 0.5;
}

.mask {
  background-color: grey;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 111;
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-top: 10px;
  font-weight: 900;
  color: white;
  line-height: 23px;
}

.horizontal-toolbar-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.vertical-toolbar-wrapper {
  width: 48px;
  height: 100%;
  display: flex;
}

.menu-item,
.toolbar-item {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #01c1d5;
}

.activated .menu-item {
  background-color: #0c4284 !important;
}

.menu-item:hover {
  background-color: white;
}

.activated .menu-item .menu-icon,
.activated .menu-item .menu-icon g {
  fill: white !important;
}

.vertical-toolbar-wrapper .menu-item {
  padding: 12px 12px;
  margin-top: 1px;
}

.float-item {
  background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  stroke: #8d96b2;
  fill: #8d96b2;
}

.float-item:hover {
  background: #f3f8fe;
}

.float-item.disabled:hover {
  background: none;
}

.float-item1 {
  position: absolute;
  left: -410px;
  background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  stroke: #8d96b2;
  fill: #8d96b2;
}

.float-item1:hover {
  background: #f3f8fe;
}

.float-item1.disabled:hover {
  background: none;
}

.ace_selection-custom {
  background: #f245e7 !important;
}

.ace_selected-word-custom {
  background-color: #faff00 !important;
  border: none !important;
}

.ace_gutter-cell {
  height: 17px !important;
}
