.tuning-modal-body {
  display: flex;
  height: 100%;
}

.tuning-modal-body .tuning-image {
  width: 100%;
  height: 100%;
}

.tuning-modal-body .right-panel {
  display: flex;
  flex-direction: column;
  width: 450px;
  font-family: "Inter";
}

.tuning-modal-body .next-button {
  width: 64px;
  height: 48px;
  margin-top: 36px;
  background: #3f74f6;
  border-radius: 11px;
  border: none;
  cursor: pointer;
}

.tuning-modal-body form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 48px;
}

.tuning-modal-body .description,
.tuning-modal-body .description-bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #414d77;
}

.tuning-modal-body .description-bold {
  font-weight: 700;
}

.tuning-modal-body input {
  width: 48px;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  border: 1.6px solid #3f74f655;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 16px;
  color: #3f74f6;
}

.tuning-modal-body input:focus,
.tuning-modal-body input:hover {
  border-color: #3f74f6;
}

.tuning-modal-body .email {
  width: 260px;
  text-align: left;
  padding-left: 10px;
  margin-top: -20px;
  margin-bottom: -5px;
}

.tuning-modal-body select.database-type {
  width: 148px;
  height: 48px;
  border: 1.6px solid #3f74f655;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 16px;
  padding-left: 10px;
  color: #3f74f6;
}

.tuning-modal-body input.required,
.tuning-modal-body .required input {
  border-color: #eba9b4;
}

.tuning-modal-body input:focus.required {
  border-color: #f02849;
  outline: none;
}

.tuning-modal-body .back-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
}

.tuning-modal-body .back-button .caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3f74f6;
  margin-left: 8px;
  margin-top: -1px;
}

.tuning-modal-body .second-pane-options-wrapper {
  margin-top: 30px;
}

.tuning-modal-body .second-pane-options-wrapper .option-selector {
  margin-top: 16px;
}

.tuning-modal-body .submit-button {
  width: 180px;
  height: 48px;
  margin-top: 20px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.tuning-modal-body .success-pane {
  height: 566px;
  background: #01c1d533;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tuning-modal-body .success-pane .tuning-avata {
  width: 54px;
  height: 52px;
  margin: 30px auto 11px auto;
}

.tuning-modal-body .success-pane .tuning-success-caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 43px;
  align-items: center;
  text-align: center;
  letter-spacing: -1px;
  color: #0c4284;
}

.tuning-modal-body .tuning-success-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0c4284;
  width: 298px;
  margin-top: 24px;
}

.tuning-modal-body .perfect-button {
  width: 118px;
  height: 48px;
  margin-top: 29px;
  margin-bottom: 47px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.agree-wrapper {
  display: inline;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin-top: 22px;
  color: #0c4284;
}
