.overlay.antipattern-mpdal {
  background: none;
  width: fit-content;
  margin: auto;
  height: fit-content;
  margin-left: 48px !important;
}
.popup.antipattern {
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 4px 4px 12px rgba(1, 39, 85, 0.1);
  border-radius: 4px;
  padding: 0px;
  width: 390px !important;
  height: 480px;
  transition: none;
}
.error .popup.antipattern {
  height: 300px;
}
.popup.antipattern .close {
  top: 9px;
  right: 9px;
}
.content {
  width: 100%;
}
.popup.popup.antipattern > .content {
  height: calc(100% - 40px);
}
.anti-pattern-body {
  height: 1400px;
  font-family: "Inter";
  color: #002a75;
}
.anti-pattern-body .caption {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edfbfc;
}

.anti-pattern-body .anti-pattern-label-wrapper {
  height: 32px;
  margin-top: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f4f7fe;
}

.caption .pattern-icon {
  /* padding-top: 7px; */
  /* margin-right: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #744a06;
}

.caption .pattern-label {
  padding-left: 5px;
  padding-right: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  display: flex;
  align-items: center;
  color: #744a06;
}

.caption .pattern-icon.error,
.caption .pattern-label.error {
  color: #9d0b23;
}

.anti-pattern-body {
  height: 100%;
}
.p-lr {
  padding-left: 25px;
  padding-right: 45px;
}

.anti-pattern-body .sub-block {
  padding: 13px 0px 15px 0px;
  border-top: 1px solid #d3d3d3;
}

.anti-pattern-body .sub-block.first-sub-block {
  border-top: none;
  padding-bottom: 20px;
  padding-top: 0px;
  /* padding-left: 40px; */
}
.anti-pattern-body .sub-block.second-sub-block {
  border-top: none;
}
.anti-pattern-body .sub-block .dropdown {
  background: #ffffff;
  border: 1px solid #f9a825;
  border-radius: 30px;
  width: 48px;
  height: 27px;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.anti-pattern-body .error .sub-block .dropdown {
  border-color: #f02849;
}
.anti-pattern-body .sub-block .dropdown span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  /* identical to box height, or 92% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #f9a825;
}
.anti-pattern-body .error .sub-block .dropdown span {
  color: #f02849;
}
.anti-pattern-body .sub-block .dropdown-content.hide {
  display: none;
}
.anti-pattern-body .sub-block .dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  width: 50px;
  border: 1px solid #f9a825;
  border-radius: 24px;
  padding: 8px 0;
  font-size: 12px;
  color: #8d96b2;
  font-weight: 500;
  z-index: 1;
}
.anti-pattern-body .error .sub-block .dropdown-content {
  border-color: #f02849;
}
.anti-pattern-body .sub-block .dropdown-content .item {
  cursor: pointer;
  padding: 8px;
}
.anti-pattern-body .sub-block .dropdown-content .item.selected {
  color: #f9a825;
}
.anti-pattern-body .error .sub-block .dropdown-content .item.selected {
  color: #f02849;
}
.anti-pattern-body .sub-block .dropdown-content .item:hover {
  border-radius: 20px;
  color: #444f64;
  background: #fef6e9;
}
.anti-pattern-body .error .sub-block .dropdown-content .item:hover {
  background: #feecef;
}
.anti-pattern-body .sub-caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  color: #744a06;
  padding-bottom: 8px;
}

.anti-pattern-body .error .sub-caption {
  color: #9d0b23;
}

.anti-pattern-body .pattern-preview {
  height: 150px;
}

.anti-pattern-body .pattern-text,
.anti-pattern-body .pattern-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  display: flex;
  align-items: center;
  color: #000000;
}

.anti-pattern-body .pattern-text {
  min-height: 20px;
}

.anti-pattern-body .pattern-description {
  min-height: 50px;
}

#heading {
  font-size: 14px;
  margin-left: 13px;
  margin-bottom: 25px;
}

#heading > span:nth-child(1),
#severity > span,
#problem > span:nth-child(1),
#solution > span:nth-child(1),
#legitimate-use-of-the-anti-pattern > span:nth-child(1),
#legitimate-use-of-anti-pattern > span:nth-child(1),
#further-reading > span:nth-child(1) {
  text-align: left;
  margin-right: 9px;
  letter-spacing: 3px;
}

.anti-pattern-body #severity,
.Anti-pattern > .heading,
#problem,
#heading,
#solution,
#legitimate-use-of-the-anti-pattern,
#legitimate-use-of-anti-pattern,
#further-reading,
#type {
  color: #744a06;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
}
#solution,
#legitimate-use-of-the-anti-pattern,
#legitimate-use-of-anti-pattern,
#further-reading {
  padding-top: 26px;
  border-top: 1px solid #eef1f4;
  margin-top: 26px;
}

.anti-pattern-body p,
.anti-pattern-body #text,
.anti-pattern-body li,
.anti-pattern-body a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.Anti-pattern {
  border-bottom: 1px solid #eef1f4;
  padding-bottom: 1rem;
}

.hr {
  width: 12px;
  color: #bdbdbd;
  background-color: #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
}

.hr1 {
  margin: 26px 0px;
  width: 100%;
  color: #bdbdbd;
  background-color: #bdbdbd;
  border-bottom: 1px solid #eef1f4;
}

.anti-pattern-body > .content a {
  text-decoration: none;
}
table {
  font-size: small;
  border-spacing: 0px;
}
thead tr {
  background-color: rgba(0, 0, 0, 5%);
}
thead tr th {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/* thead tr:nth-child(even) {background: #FFF} */
tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 5%);
}
tbody tr:nth-child(odd) {
  background: #fff;
}

td,
th {
  padding: 0.75rem;
  vertical-align: top;
}
td {
  border-top: 1px solid #dee2e6;
}

.Anti-pattern > .heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 36px;
}
