.content-wrapper {
  width: 100%;
  height: calc(100vh - 62px);
  background-color: lightgray;
  padding: 0px;
  display: flex;
  background-color: #fffdf4;
  background-image: radial-gradient(currentColor 1.5px, transparent 1.5px),
    radial-gradient(currentColor 1.5px, transparent 1.5px);
  background-size: calc(20 * 1.5px) calc(20 * 1.5px);
  background-position: 0 0, calc(10 * 1.5px) calc(10 * 1.5px);
  color: #d2d2d2 !important;
}

.fullscreen .content-wrapper {
  height: 100vh;
}

.gutter {
  cursor: url(../../assets/icons/verticallyresize.svg), auto;
  height: 100%;
  position: relative;
  border-right: 2px solid #eef1f4;
  position: relative;
  z-index: 99;
}

.gutter:hover {
  border-right: 2px solid lightgray;
}

.gutter:after {
  position: absolute;
  content: "";
  color: #000;
  left: -2px;
  top: 50%;
  opacity: 0.5;
}

.diagram-wrapper {
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0px;
}

.diagram-box {
  height: 100%;
  position: relative;
}
