.format-sql-wrapper {
  width: 126px;
  height: 48px;
  background-color: #01c1d5;
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  margin-right: 2px;
}

.format-sql-wrapper .checkmark-btn {
  width: 97px;
  text-align: center;
  background-color: #0000;
  color: white;
  border: none;
  cursor: pointer;
  line-height: 48px;
  font-size: 16px;
  font-weight: 600;
}

.format-sql-wrapper .option-handle {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  width: 29px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}

.format-sql-wrapper .checkmark-btn[disabled],
.option-handle.disabled {
  opacity: 0.45;
  pointer-events: none;
  cursor: default !important;
}

.format-sql-wrapper .option-handle:hover svg {
  fill: #4ad1e1;
}

/* .format-sql-wrapper .option-pane {
    width: 178px;
    height: 212px;
    background-color: white;
    box-shadow: 0px 1px 7px rgba(12, 66, 132, 0.13);
    border-radius: 20px;
    z-index: 6;
    position: absolute;
    left: -12px;
    top: -212px;
} */
.format-sql-wrapper .option-pane {
  width: 178px;
  height: 130px;
  background-color: white;
  box-shadow: 0px 1px 7px rgb(12 66 132 / 13%);
  border-radius: 20px;
  z-index: 6;
  position: absolute;
  left: -12px;
  top: -131px;
}

.format-sql-wrapper .option-pane li {
  color: #8d96b2;
  width: 160px;
  margin-left: -30px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  list-style: none;
  cursor: pointer;
}

.format-sql-wrapper .option-pane li.activated::before {
  content: "\2022" !important;
  color: #01c1d5 !important;
  font-weight: bold !important;
  display: inline-block !important;
  width: 1em !important;
  margin-left: -1em !important;
}

.format-sql-wrapper .option-pane li.activated,
.format-sql-wrapper .option-pane li.activated #wrap_chars {
  color: #01c1d5;
}

.format-sql-wrapper.formatted .option-pane li.activated,
.format-sql-wrapper.formatted .option-pane li.activated #wrap_chars,
.format-sql-wrapper.formatted .option-pane li.activated::before {
  color: #0c4284 !important;
}

.format-sql-wrapper .option-pane li:hover {
  background-color: #f4f7fe;
  border-radius: 10px;
}

.format-sql-wrapper .option-pane li:hover::before {
  color: white;
}

.format-sql-wrapper .option-pane .option-margin {
  height: 113px;
}

.format-sql-wrapper .option-pane .option-margin ul {
  margin-bottom: 5px;
}

.format-sql-wrapper .option-pane .split-line {
  border: solid 0.5px #e4e5e8;
  width: 144px;
  margin: auto;
}

.format-sql-wrapper .option-pane .option-wrap {
  height: 70px;
}

.format-sql-wrapper .option-pane .option-wrap ul {
  margin-top: 5px;
}

.format-sql-wrapper #wrap_chars {
  width: 33px;
  height: 30px;
  margin: 0px 2px;
  background: #f4f7fe;
  border: 0.9px solid rgba(141, 150, 178, 0.26);
  box-sizing: border-box;
  border-radius: 11px;
  text-align: center;
  color: #8d96b2;
}

.format-sql-wrapper #wrap_chars:focus {
  outline: none;
}
