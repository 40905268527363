.diagram-svg {
  width: 100%;
  height: 100%;
  background-color: #fffdf4;
  background-image: radial-gradient(currentColor 1.5px, transparent 1.5px),
    radial-gradient(currentColor 1.5px, transparent 1.5px);
  background-size: calc(20 * 1.5px) calc(20 * 1.5px);
  background-position: 0 0, calc(10 * 1.5px) calc(10 * 1.5px);
  color: #d2d2d2 !important;
}

svg {
  shape-rendering: geometricprecision;
  text-rendering: geometricprecision;
}

.node text,
.notify text {
  font-family: "Inter";
}

.diagram-box .float-toolbox {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 79px;
  height: 32px;
}

.diagram-box .float-toolbox.hidden-setting {
  width: 32px;
}

.zoom-notify-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 19px;
  right: 137px;
  height: 32px;
  width: 111px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 8%);
  background-color: white;
}

.scale-notify-box {
  font-size: 12px;
  text-anchor: end;
  color: #7e8fb1;
  font-weight: 800;
  line-height: 32px;
}

.node .dataset-body {
  fill: white !important;
}

.node .dataset-header {
  fill: white;
  stroke: #0000;
}

.node .dataset-icon-box {
  stroke: none !important;
  pointer-events: none;
  opacity: 0.06;
}

.node .dataset-icon-split-line,
.node .detail-split-line {
  stroke-width: 0.3px;
  opacity: 0.3;
}

.node .dataset-label {
  font-weight: 700;
  pointer-events: none;
}

.node .detail-label {
  font-weight: initial;
  font-size: 12px;
  pointer-events: none;
}

.node .dataset {
  fill: darkgray;
  stroke: darkgray;
}

.node .dataset.physical {
  fill: #3f74f6;
  stroke: #3f74f6;
}

.node .dataset.logical,
.node .dataset.select {
  fill: #a53ff6;
  stroke: #a53ff6;
}

.node .dataset.cte {
  fill: #f6813f;
  stroke: #f6813f;
}

.node .dataset.udtf,
.node .dataset.functional {
  fill: #414d77;
  stroke: #414d77;
}

.node .dataset.recursive {
  fill: #009ba5;
  stroke: #009ba5;
}

.node .dataset.sourceless {
  fill: #f62f47;
  stroke: #f62f47;
}

/** disabled styles **/
.node.disabled {
  cursor: not-allowed;
}

/** highlight style */
.node.highlighted .dataset-icon,
.node.highlighted .dataset-label {
  fill: white;
}

.node.highlighted .detailview-handle {
  fill: white;
  stroke: white;
}

.node.highlighted .dataset-icon-split-line {
  stroke: #ffffff;
}

.node.highlighted .dataset-body {
  stroke-width: 2px;
  filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.18));
}

.node.highlighted .frame {
  stroke: grey;
  fill: darkgrey !important;
}

.node.highlighted .dataset.physical .frame {
  stroke: #3f74f6;
  fill: #2768f4 !important;
}

.node.highlighted .dataset.logical .frame,
.node.highlighted .dataset.select .frame {
  stroke: #a53ff6;
  fill: #9621f4 !important;
}

.node.highlighted .dataset.cte .frame {
  stroke: #f6813f;
  fill: #f56f24 !important;
}

.node.highlighted .dataset.udtf .frame,
.node.highlighted .dataset.functional .frame {
  stroke: #414d77;
  fill: #273978 !important;
}

.node.highlighted .dataset.recursive .frame {
  stroke: #009ba5;
  fill: #017880 !important;
}

.node.highlighted .dataset.sourceless .frame {
  stroke: #f62f47;
  fill: #f60824 !important;
}

/** operator style */
.operator {
  stroke: black;
  fill: black;
}

/** edge style */
g .path,
g .recursive-path,
.node .handle-connector {
  fill: none;
  stroke: #6bb2d5;
  stroke-width: 2px;
  marker-end: url("#edgeEndmarker");
}

#edgeEndmarker {
  fill: #6bb2d5;
}

g .recursive-path {
  stroke: #810029;
  marker-end: url("#recursiveEdgeEndmarker");
}

g.edge path:hover {
  stroke: gold;
}

.hidden path {
  stroke-width: 0px;
}

/** tooltip style */
.notify .notify-panel {
  fill: #477cff;
  stroke: #3168f0;
  filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.18));
}

.notify .tooltip-text {
  fill: white;
  pointer-events: none;
}

.haddle-near {
  stroke: none !important;
  fill: #00000000 !important;
  cursor: pointer;
}

/** detail view style */
.dataset-detail-view {
  opacity: 1;
  transition: opacity 1000ms;
}

.dataset-detail-view.fade {
  opacity: 0;
  transition: opacity 300ms;
}

.dataset-detail-view .detail-wrapper {
  fill: #0000;
  stroke: none;
}

.detail-column-text {
  color: black;
  display: inline flow-root list-item;
  width: calc(100% - 10px);
  margin-left: 10px;
  height: inherit;
  overflow: auto;
  resize: none;
  border: none;
  outline: none;
  scrollbar-color: #d2d2d2 white;
  scrollbar-width: thin !important;
  line-height: 23px;
  cursor: auto;
}

.detail-column-text::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.detail-column-text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d2d2d2;
}

/** unused style */
.unused-bound-rect {
  fill: none;
  stroke: #414141;
  stroke-width: 2px;
  stroke-dasharray: 10, 5;
  pointer-events: none;
}

.node.unused * {
  stroke: grey;
  fill: white;
}

.node.unused use {
  fill: grey;
}

.node.unused text,
.node.unused tspan {
  fill: grey;
  stroke: none;
}

.node.unused g.edge path {
  stroke: #8b8b8b !important;
}

.fill-yellow {
  fill: #faff00;
  stroke: none;
}

.fill-pink {
  fill: #f245e7;
  stroke: none;
}
.rotate90 {
  rotate: 90deg;
  -webkit-transform: translate(-15px, -28px) scale(2) !important;
  -moz-transform: translate(-15px, -28px) scale(2) !important;
  -ms-transform: translate(-15px, -28px) scale(2) !important;
  -o-transform: translate(-15px, -28px) scale(2) !important;
  transform: translate(-15px, -28px) scale(2) !important;
}

.rotate-90 {
  rotate: -90deg;
  -webkit-transform: translate(-15px, 0px) scale(2) !important;
  -moz-transform: translate(-15px, 0px) scale(2) !important;
  -ms-transform: translate(-15px, 0px) scale(2) !important;
  -o-transform: translate(-15px, 0px) scale(2) !important;
  transform: translate(-15px, 0px) scale(2) !important;
}

.down_stream {
  rotate: 180deg;
  -webkit-transform: translate(-15px, -14px) scale(2) !important;
  -moz-transform: translate(-15px, -14px) scale(2) !important;
  -ms-transform: translate(-15px, -14px) scale(2) !important;
  -o-transform: translate(-15px, -14px) scale(2) !important;
  transform: translate(-15px, -14px) scale(2) !important;
}
.up_stream {
  -webkit-transform: translate(-15px, -15px) scale(2) !important;
  -moz-transform: translate(-15px, -15px) scale(2) !important;
  -ms-transform: translate(-15px, -15px) scale(2) !important;
  -o-transform: translate(-15px, -15px) scale(2) !important;
  transform: translate(-15px, -15px) scale(2) !important;
}

.transform {
  transform: translate(5.25px, 9.25px);
}
.transform-semi-join {
  transform: translate(5.25px, 10.25px);
}
.transform-anti-join {
  transform: translate(10.25px, 10.25px);
}
.node.highlighted .operator {
  filter: invert(100%);
  stroke: none;
}
