.signup-modal-body {
  display: flex;
  height: 100%;
}

.signup-modal-body .signup-image {
  width: 100%;
  height: 100%;
}

.signup-modal-body .left-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  font-family: "Inter";
}

.signup-modal-body .dropdown-container {
  margin-top: 104px;
}

.signup-modal-body .next-button {
  width: 64px;
  height: 48px;
  margin-top: 36px;
  background: #3f74f6;
  border-radius: 11px;
  border: none;
  cursor: pointer;
}

.signup-modal-body .back-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
}
.signupPopup {
  background-color: red;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 100;
  display: flex;
}
.signupPopup .popup {
  margin: 20px auto;
  padding: 10px;
  background: #fff;
  border-radius: 23px;
  width: auto;
  transition: all 0.5s ease-in-out;
  height: fit-content;
}
.signup-modal-body .back-button .caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3f74f6;
  margin-left: 8px;
  margin-top: -1px;
}

.signup-modal-body .signup-avata {
  width: 62.31px;
  height: 60px;
  margin: 0px auto 11px auto;
}

.signup-modal-body .margin {
  margin: 43px auto 11px auto;
}

.signup-modal-body .signup-caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
  color: #0c4284;
  margin-bottom: 21px;
  margin-top: 10px;
}

.signup-modal-body form {
  display: flex;
  flex-direction: column;
}

.signup-modal-body input,
.signup-modal-body textarea {
  width: 300px;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 18px;
  border: 1.6px solid rgba(63, 116, 246, 0.3);
  border-radius: 6px;
  margin-top: 10px;
}
.signup-modal-body textarea {
  height: auto;
  resize: none;
  resize: none;
}

.signup-modal-body input:focus,
.signup-modal-body input:hover,
.signup-modal-body textarea:focus,
.signup-modal-body textarea:hover {
  border-color: #3f74f6;
}
.signup-modal-body textarea::placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  /* color: #c0ccda; */
}
.signup-modal-body .password-container {
  position: relative;
}

.password-container .show-password-icon {
  position: absolute;
  right: 16px;
  top: 29px;
  cursor: pointer;
}

.signup-modal-body .register-button {
  width: 300px;
  height: 45px;
  margin-top: 10px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.signup-modal-body .signup-agree-container {
  display: inline;
  margin-top: 12px;
  width: 250px;
}

.signup-modal-body .error {
  font-size: 11px;
  color: #f02849;
  width: 300px;
  line-height: 12px;
  margin-left: 1px;
  margin-top: 5px;
}

.signup-modal-body input.required,
.signup-modal-body textarea.required,
.signup-modal-body .required input {
  border-color: #eba9b4;
  color: #eba9b4;
}

.signup-modal-body input:focus.required,
.signup-modal-body textarea:focus.required {
  border-color: #f02849;
  color: #f02849;
  outline: none;
}

.signup-modal-body .signup-success-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0c4284;
  width: 400px;
  margin-top: 20px;
}

.signup-modal-body .return-button {
  width: 228px;
  height: 48px;
  margin-top: 33px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.show {
  display: none !important;
}

.password-instructions {
  font-size: 11px;
  margin: 2%;
  width: 300px;
}
.right-panel {
  height: 100%;
  width: 60%;
  padding-top: 5px;
}
@media (min-height: 945px) {
  .signupPopup .popup {
    align-self: center;
  }
}
