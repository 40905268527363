.login-modal-body {
  display: flex;
  height: 100%;
}

.login-modal-body .left-panel .login-image {
  width: 100%;
  height: 100%;
}

.login-modal-body .right-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
  font-family: "Inter";
}

.login-modal-body .right-panel .login-avata {
  width: 62.31px;
  height: 60px;
  margin: 66px auto 34px auto;
}

.login-modal-body .login-caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 55px;
  text-align: center;
  letter-spacing: -1px;
  color: #0c4284;
  margin-bottom: 49px;
  margin-top: 50px;
  height: 108px;
  width: 225px;
}

.login-modal-body form {
  display: flex;
  flex-direction: column;
}

.login-modal-body input {
  width: 263px;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 18px;
  border: 1.6px solid rgba(63, 116, 246, 0.3);
  border-radius: 6px;
  color: #3f74f6;
}

.login-modal-body input:focus {
  border-color: #3f74f6;
}

.login-modal-body .password {
  margin-top: 12px;
}

.login-modal-body .login-forgot-password {
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: #0c4284;
  text-align: center;
  margin-top: 25px;
  cursor: pointer;
}

.login-modal-body .login-button {
  width: 263px;
  height: 48px;
  margin-top: 16px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.login-modal-body .login-register-container {
  align-self: center;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  margin-top: 80px;
}

.login-modal-body .login-register {
  font-weight: 700;
  color: #0c4284;
  margin-left: 5px;
  cursor: pointer;
}

.login-modal-body .login-failed-notify {
  width: 263px;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 18px;
  margin-bottom: 10px;
  border: 1.6px solid #f02849;
  border-radius: 6px;
  background: rgba(240, 40, 73, 0.05);
  display: flex;
  align-content: stretch;
  align-items: center;
}

.login-modal-body .login-failed-notify .error-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 14px;
  color: #414d77;
  margin-left: 11px;
}

.login-modal-body input.required {
  border-color: #eba9b4;
  color: #eba9b4;
}

.login-modal-body input:focus.required {
  border-color: #f02849;
  color: #f02849;
  outline: none;
}
