.profile-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.profile-container .profile-category {
  width: 235px;
  height: 100%;
  border-right: solid 1px #eef1f4;
}

.profile-container .profile-category-header,
.profile-container .profile-content-header {
  width: 235px;
  height: 28px;
  border-bottom: solid 1px #eef1f4;
}

.profile-container .profile-content-header {
  width: 100%;
}

.profile-container .profile-category-content {
  width: 100%;
  height: 100%;
}

.profile-container .profile-category-item {
  height: 48px;
  border-bottom: solid 1px #eef1f4;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #414d77;
  fill: #414d77;
  background: white;
}

.profile-container .profile-category-item.seperator,
.profile-container .profile-category-item.data {
  cursor: default;
  justify-content: center;
}

.profile-container .profile-category-item.selected {
  color: #3f74f6;
  fill: #3f74f6;
  background: #f4f7fe;
}

.profile-container .profile-category-item-icon {
  width: 65px;
  display: flex;
  align-content: space-around;
}

.profile-container .profile-category-item-icon svg {
  margin: auto;
}

.profile-container .profile-content {
  width: 100%;
}

.profile-container .profile-content-body {
  display: flex;
}

.profile-container .profile-content-body-left {
  width: 592px;
  margin: 50px;
  border-radius: 24px;
  box-shadow: 1px 1px 8px rgba(1, 193, 213, 0.2);
}

.profile-container .profile-content-body-right {
  width: 260px;
  height: 511px;
  background: #01c1d533;
  border-radius: 24px;
  margin-top: 48px;
}

.profile-container .profile-content-body-right #input-file-upload {
  display: none;
}

.profile-container .user-picture-row {
  display: flex;
  margin-top: 28px;
}

.profile-container .user-picture-wrapper {
  margin-left: 28px;
  display: flex;
}

.profile-container .user-picture {
  width: 80px;
  height: 80px;
  background-color: rgb(247, 153, 169);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.profile-container .add-picture {
  cursor: pointer;
  margin-left: -24px;
  margin-top: 56px;
}

.profile-container .user-type {
  width: 70px;
  height: 26px;
  border: 1px solid #3f74f6;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #3f74f6;
  margin-left: 24px;
  margin-top: auto;
  margin-bottom: auto;
}

.profile-container .profile-hello {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  color: #002a75;
  margin-left: 28px;
  margin-top: 24px;
}

.profile-container .profile-user-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  color: #3f74f6;
  margin-left: 28px;
  margin-top: 4px;
  width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.profile-container .profile-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #767d96;
  width: 156px;
  margin-left: 28px;
  margin-top: 14px;
}

.profile-container .profile-image {
  margin-left: 8px;
  margin-top: 25px;
}

.profile-container .profile-content-body-left {
  padding: 50px;
}

.profile-container .profile-content-body label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #414d77;
}

.profile-container .profile-content-body .profile-input,
.profile-container .profile-content-body .profile-select input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 18px;
  width: 492px;
  height: 48px;
  border: 1.6px solid rgba(63, 116, 246, 0.3);
  color: #0c4284;
  border-radius: 6px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 14px;
}

.profile-container .profile-content-body .update-button {
  width: 185px;
  height: 48px;
  margin: 16px 154px 0px 154px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.profile-container .profile-agree-container {
  align-self: center;
  display: flex;
  margin-top: 30px;
}

.profile-container .profile-agree {
  display: flex;
}

.profile-container .profile-privacy {
  color: #3f74f6;
  margin-left: 4px;
  cursor: pointer;
}

.profile-container .profile-password-description {
  width: 492px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0c4284;
  margin-top: 7px;
  margin-bottom: 10px;
}

.profile-container .password-success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-success-wrapper .success-icon {
  margin-top: 40px;
}

.password-success-wrapper .success-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 20px;
  color: #0c4284;
  margin-top: 17px;
}

.password-success-wrapper .profile-password-description {
  margin-top: 27px;
  text-align: center;
}

.password-success-wrapper .resend-button {
  margin-top: 30px;
  width: 140px;
  height: 48px;
  border: 1.6px solid #3f74f6;
  border-radius: 6px;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3f74f6;
  cursor: pointer;
}

.profile-container .error {
  font-size: 11px;
  color: #f02849;
  width: 263px;
  line-height: 12px;
  margin-left: 1px;
}

.profile-container input.required,
.profile-container .required input {
  border-color: #eba9b4;
}

.profile-container input:focus.required {
  border-color: #f02849;
  color: #f02849;
  outline: none;
}

.profile-container .resetpassword-notify {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
  color: #3f74f6;
}

.profile-container .resetpassword-notify.fail {
  color: #f02849;
}
