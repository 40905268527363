.post-registration-modal.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.post-registration-modal .modal-content {
  background-color: #fff;
  border-radius: 23px;
  display: flex;
  flex-direction: row;
  left: 50%;
  margin: 20px auto;
  padding: 15px 45px 15px 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}

.post-registration-modal .modal-content .left-content {
  align-items: center;
  color: #0c4284;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 20px;
  padding: 10% 10%;
  width: 62.5%;
}

.post-registration-modal .modal-content .left-content .logo {
  height: 60px;
  width: 60px;
}

.post-registration-modal .modal-content .left-content .caption {
  font-size: 32px;
  font-weight: 700;
}

.post-registration-modal .modal-content .left-content .verification-text {
  text-align: center;
}

.post-registration-modal .modal-content .left-content .return-button {
  background-color: #3f74f6;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 600;
  height: 48px;
  width: 228px;
}

.post-registration-modal .modal-content .success {
  height: auto;
}

.post-registration-modal .modal-content .close-button {
  cursor: pointer;
  height: 15px;
  position: absolute;
  right: 20px;
  top: 15px;
  width: 15px;
}
