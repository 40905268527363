.feedback-modal-body {
  display: flex;
}

.feedback-popup {
  height: 95vh;
  margin: 20px auto;
  padding: 10px;
  background: #fff;
  border-radius: 23px;
  width: auto;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.feedback-popup .feedback-content {
  height: 100%;
  overflow-y: scroll;
}

.feedback-popup .feedback-content::-webkit-scrollbar {
  height: 1px;
  width: 5px;
}

.feedback-popup .feedback-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.feedback-popup .feedback-content::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
  outline: 1px solid transparent;
}

.feedback-popup .close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 25px;
  right: 30px;
  transition: all 200ms;
  font-size: 45px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  /* it should higher that overlay z-index 100 */
  z-index: 101;
  width: 11.5px;
  height: 11.5px;
}

.feedback-popup .close:hover {
  cursor: pointer;
  color: hsl(0, 0%, 55%);
}

.feedback-modal-body .right-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 455px;
  padding: 20px;
  font-family: "Inter";
  color: #0c4284;
}

.feedback-modal-body .try-modal-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 30px auto 20px auto;
  text-align: center;
}

.feedback-modal-body form {
  width: 80%;
  min-width: 350px;
}

.feedback-modal-body input.entry-text {
  width: 100%;
  height: 48px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 2px solid #808184;
  border-radius: 4px;
  padding: 10px 18px;
  resize: none;
  margin-top: 10px;
}

.feedback-modal-body textarea {
  width: 100%;
  height: 48px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 2px solid #808184;
  border-radius: 4px;
  padding: 10px 18px;
  resize: none;
}

.text {
  font-size: 14px;
  text-align: left;
  font-weight: 700;
  color: #808184;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.feedback-comment {
  font-size: 14px;
  text-align: left;
  font-weight: 700;
  color: #808184;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 2px;
}

.feedback-modal-body input.entry-text:focus,
.feedback-modal-body input.entry-text:hover,
.feedback-modal-body textarea:focus,
.feedback-modal-body textarea:hover {
  border-color: #808184;
}

.feedback-modal-body .case-item {
  padding-left: 0px;
  padding-bottom: 8px;
  margin-top: 0px;
}

.feedback-modal-avata {
  width: 62.31px;
  height: 65px;
  margin-bottom: 5px;
  /* margin: 73px auto 36px auto; */
}

.feedback-modal-header1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
  color: #0c4284;
  /* margin-bottom: 15px; */
}

.feedback-modal-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
  color: #0c4284;
  /* margin-bottom: 15px; */
}

.feedback-modal-body .case-item label {
  padding: 14px;
  border: 2px solid #808184;
  background-color: rgba(255, 255, 255, 0.08);
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
}

.css-zjchdv-control,
.css-zjchdv-control:hover {
  border-color: #808184 !important;
  box-shadow: none !important;
}

.css-1xwlf18-control,
.css-1xwlf18-control:hover {
  border-color: #808184 !important;
  outline: none;
  box-shadow: none !important;
}
.feedback-modal-body .case-item label.selected {
  /* background-color: rgba(63, 116, 246, 0.08); */
  /* border-color: #3F74F6; */
  background-color: white !important;
}

.feedback-modal-body .case-item label span:last-child {
  padding-left: 14px;
  color: #8d96b2;
}

.feedback-modal-body .case-item label.selected span:last-child {
  /* color: #3F74F6; */
}

.feedback-modal-body .submit-button {
  width: 100%;
  height: 50px;
  margin-top: 16px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.feedback-space-text {
  margin-top: 10px;
}

.feedback-modal-body input.entry-text.required,
.feedback-modal-body textarea.required {
  border-color: #eba9b4;
}

.feedback-modal-body input.entry-text:focus.required,
.feedback-modal-body textarea:focus.required {
  border-color: #f02849;
  outline: none;
}

.feedback-modal-body .error {
  font-size: 11px;
  color: #f02849;
  width: 263px;
  line-height: 12px;
  margin-left: 1px;
}

.feedback-modal-body .feedback-notify {
  width: 320px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 4px;
}

.feedback-modal-body .feedback-notify.fail {
  border: 1px solid #f02849;
  background: #ffeded;
  color: #661d1d;
}

.feedback-modal-body .feedback-notify.success {
  border: 1px solid #13ce66;
  background: #e7faf0;
  color: #085229;
}
