.logo-wrapper {
  position: absolute;
  height: 34px;
  width: 48px;
}

.logo-img {
  height: 30px;
  width: 40px;
  margin-top: 2px;
  margin-left: 3px;
}

.left-side-bar {
  width: 48px;
  min-width: 48px;
  height: 100vh;
}

.fullscreen .left-side-bar {
  display: none;
}
