.about-container {
  position: relative;
  background: #b3ecf2;
  width: 100%;
  height: 100%;
  color: #0c4284;
}

.about-container .highlight-color {
  color: #01c1d5;
}

.about-container .about-header {
  height: 34px;
  border-bottom: 1px solid #f8fbfe;
}

.about-container .about-content {
  height: 100%;
  border-bottom: 1px solid #f8fbfe;
}

.about-container .about-header-left,
.about-container .about-content-body {
  width: 1295px;
  height: calc(100vh - 97px);
  overflow-y: auto;
  border-right: 1px solid #f8fbfe;
}

.about-container .about-pane-navigator-wrapper {
  position: absolute;
  left: 970px;
  top: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-container .about-navigator {
  width: 12px;
  height: 12px;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.about-navigator .about-navigator-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #01c1d5;
  pointer-events: none;
}

.about-navigator.activated .about-navigator-circle {
  background: #0c4284;
}

.about-navigate-pane-indicator {
  margin-top: 317px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
}

.about-container .about-content-body-wrapper {
  width: 990px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-container .about-caption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.5px;
  margin-top: 36px;
}

.about-container .about-text-body {
  font-size: 16px;
}

.about-container .about-text-body .bold {
  font-weight: 800;
}

.about-container .about-box {
  width: 894px;
  height: 452px;
  margin-top: 36px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  filter: drop-shadow(1px 1px 8px rgba(1, 193, 213, 0.4));
  border-radius: 24px;
  display: flex;
}

.about-container .about-pane1-image {
  margin: 60px 15px 60px 64px;
}

.about-container .about-text-wrapper {
  margin: 55px;
  color: #0c4284;
}
.about-container .about-sub-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 31px;
  margin-bottom: 38px;
}

.about-pane1 .about-text-wrapper {
  width: 358px;
}

.about-pane2 .about-text-wrapper {
  width: 350px;
}

.about-container .about-pane2-image {
  margin: 64px 64px 64px 15px;
}

.about-pane3 .about-box {
  height: 1294px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
}

.about-pane3 .about-pane-top {
  display: flex;
  height: 1100px;
}

.about-pane3 .about-pane-left {
  width: 450px;
  padding-right: 15px;
}

.about-pane31-image {
  margin: 64px 15px 234px 64px;
}

.about-box ul {
  list-style-position: outside;
  list-style: square;
}

.about-box .about-second-li {
  margin-left: 40px;
}

.about-second-li li {
  color: #01c1d5;
}

.about-second-li p {
  color: #0c4284;
}

.about-text-body .diamond-indent {
  list-style: "◇  ";
}

.about-text-body .italic-highlighted {
  font-style: italic;
  color: #01c1d5;
}

.about-pane32-image {
  margin: 89px auto 64px auto;
}

.about-pane3 .about-pane-bottom {
  height: 194px;
}

.about-pane3-split-line {
  border: 1px solid #f8fbfe;
  width: 90%;
}

.about-pane3 .about-pane-bottom .about-text-body {
  padding-top: 20px;
  padding-right: 64px;
}
