.tablelist-box {
  padding-left: 10px;
  width: 100%;
  height: 100%;
  overflow: scroll !important;
}

.tablelist-box::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.tablelist-box::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.tablelist-box::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}

.tablelist-box::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
  outline: 1px solid transparent;
  border-radius: 10px;
}

.tablelist-box ul {
  padding-inline-start: 7px;
  margin-block-start: 29px;
}

.table-type-wrapper {
  display: flex;
  align-items: center;
  fill: #002a75;
}

.table-type-wrapper .bullet-text {
  margin-left: 7px;
}

.table-type-wrapper .bullet-text.physical-table {
  color: #3f74f6;
}

.table-type-wrapper .bullet-text.inline-table {
  color: #a53ff6;
}

.table-type-wrapper .bullet-text.recursive-table {
  color: #009ba5;
}

.table-type-wrapper .bullet-text.pseudo-table {
  color: #f62f47;
}

.table-type-wrapper .bullet-text.function-table {
  color: #414d77;
}

.table-type-wrapper .bullet-text.common-table {
  color: #f6813f;
}

bullet-text {
  display: flex;
  align-items: center;
  margin-left: 7px;
  font-size: 16px;
  font-weight: bold;
}

.tablelist-box li {
  list-style: none;
  font-size: 14px;
  font-weight: normal;
  padding-inline-start: 6px;
  margin-block-start: 15px;
}

.tablelist-box li label {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 2px;
}

.item-name {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tablelist-box li label.checked {
  background: #e4fcff;
  border-color: #c8e7eb;
}

.tablelist-box li label.disabled {
  cursor: not-allowed;
}

.table-item-checkbox {
  margin-right: 12px;
  height: 16px;
  width: 16px;
  -webkit-appearance: none;
  border: 1px solid #dbdbdd;
  border-radius: 2px;
  background-color: #f5f5f5;
}

.table-item-checkbox:checked {
  -webkit-appearance: auto;
}

.table-item-checkbox[disabled] {
  cursor: not-allowed;
}

.tablelist-box li span {
  cursor: pointer;
}

.tablelist-box li span:hover {
  background-color: lightgrey;
}

.tablelist-box li span.highlighted {
  background-color: #b5d5ff;
}
