.try-modal-body {
  display: flex;
  height: 100%;
}

.try-modal-body .left-panel .try-image {
  width: 100%;
  height: 100%;
}

.try-modal-body .right-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 455px;
  padding: 0px 70px;
  font-family: "Inter";
  color: #0c4284;
}

.try-modal-body .try-modal-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 30px auto 20px auto;
  text-align: center;
}

.try-modal-body form .entry-label {
  font-size: 14px;
  text-align: left;
  font-weight: 700;
  color: #0c4284;
  margin-bottom: 5px;
}

.try-modal-body .email,
.try-modal-body .feedback-textarea {
  width: 100%;
  height: 38px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 2px solid #3f74f655;
  border-radius: 4px;
  padding-left: 10px;
  resize: none;
}

.try-modal-body .email:focus,
.try-modal-body .email:hover,
.try-modal-body .feedback-textarea:focus,
.try-modal-body .feedback-textarea:hover {
  border-color: #3f74f6;
}

.try-modal-body .try-form-usecase-wrapper {
  margin-top: 10px;
}

/* Start Use Case Styles */
.try-modal-body .try-form-usecase-wrapper .case-item {
  padding-left: 0px;
  padding-bottom: 8px;
  margin-top: 0px;
}

.try-modal-body .try-form-usecase-wrapper .case-item label {
  padding: 14px;
  border: 2px solid #3f74f655;
  background-color: rgba(255, 255, 255, 0.08);
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
}

.try-modal-body .try-form-usecase-wrapper .case-item label.selected {
  background-color: rgba(63, 116, 246, 0.08);
  border-color: #3f74f6;
}

.try-modal-body .try-form-usecase-wrapper .case-item label span:last-child {
  padding-left: 14px;
  color: #8d96b2;
}

.try-modal-body
  .try-form-usecase-wrapper
  .case-item
  label.selected
  span:last-child {
  color: #3f74f6;
}

.try-modal-body .try-form-feedback-wrapper {
  margin-top: 10px;
}

.try-modal-body .agree-wrapper {
  align-self: center;
  display: inline;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin-top: 12px;
  color: #0c4284;
  width: 240px;
}

.try-modal-body .privacy-agree {
  display: flex;
}

.try-modal-body .try-button {
  width: 150px;
  height: 40px;
  margin-top: 16px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.try-modal-body .thanks-message {
  margin-top: 30px;
}

.try-modal-body .email.required,
.try-modal-body .feedback-textarea.required {
  border-color: #eba9b4;
}

.try-modal-body .email:focus.required,
.try-modal-body .feedback-textarea:focus.required {
  border-color: #f02849;
  outline: none;
}
.try-modal-body .privacy-agree.required {
  color: #f02849;
}

.try-modal-body .error {
  font-size: 11px;
  color: #f02849;
  width: 263px;
  line-height: 12px;
  margin-left: 1px;
}
