.workflow-setting-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.workflow-setting-container .workflow-setting-category {
  width: 300px;
  height: 100%;
  border-right: solid 1px #eef1f4;
}

.workflow-setting-container .workflow-setting-category-header {
  width: 300px;
  height: 28px;
  border-bottom: solid 1px #eef1f4;
}
.workflow-setting-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.workflow-setting-container {
  width: 100%;
  /* background-color: #F4F7FE; */
  display: flex;
}
.workflow-setting-container .workflow-setting-content-header {
  width: 100%;
}

.workflow-setting-content .workflow-setting-content-body {
  margin: 60px;
  margin-left: 100px;
}
.plan-paragrah p {
  margin-bottom: 10px;
}

.padding {
  padding-bottom: 50px;
}
.querycolor {
  color: #fe1818;
}
.workflow-setting-container .workflow-setting-category-item {
  height: 48px;
  border-bottom: solid 1px #eef1f4;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #414d77;
  fill: #414d77;
  background: white;
}

.workflow-setting-container .workflow-setting-category-item-icon {
  width: 65px;
  display: flex;
  align-content: space-around;
}

.workflow-setting-container .workflow-setting-category-item-icon svg {
  margin: auto;
}

.workflow-setting-category-item-number {
  color: #01c1d5;
  margin-left: -7px;
  margin-right: 15px;
}

.plan-content {
  height: 180px;
}

.plan-content .plan-paragrah {
  margin-top: 70px;
}

.plan-content .main_div {
  margin-top: 70px;
}

.headers {
  color: #a53ff6;
}
.headers1 {
  color: #a53ff6;
  margin-bottom: 40px;
}
.url {
  color: #f9a825;
  cursor: pointer;
}
button.url {
  align-items: initial;
  appearance: initial;
  background-color: initial;
  display: inline;
  font-family: "Inter";
  font-feature-settings: initial;
  font-kerning: initial;
  font-optical-sizing: initial;
  font-size: initial;
  letter-spacing: initial;
  line-height: initial;
  text-align: initial;
  text-indent: initial;
  text-rendering: initial;
  word-spacing: initial;
  writing-mode: initial;
  -webkit-font-smoothing: initial;
  border: none;
  width: auto;
  height: auto;
  padding: initial;
}
.details-bold {
  color: #414d77;
}
.details-bold3 {
  color: #414d77;
  margin-bottom: 1.33em;
}
.details-bold1 {
  color: #414d77;
  margin-top: 50px;
  padding-bottom: 2rem;
}
.details-bold2 {
  color: #414d77;
  margin-top: 15px;
  padding-bottom: 2rem;
}
.note-text {
  color: #414d77;
  margin-top: 1.33em;
}
.sdkquery {
  max-width: 600px;
  background-color: white;
  border-radius: 8px;
  margin-left: 60px;
  position: relative;
}
.sdkquery:hover .sdkquery-copy-btn {
  opacity: 1;
}
.sdkquery-copy-btn {
  height: 32px;
  width: 32px;
  background-color: white;
  position: absolute;
  right: 10px;
  bottom: 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;
  border: 0;
}

.sdkquery-copy-btn:active {
  height: 30px;
  width: 30px;
}
.sdkquery-copy-btn:active .copy_svg {
  width: 16px;
}
.sdkquery-content {
  font-size: 13px;
  padding: 1rem 1rem;
  line-height: 20px;
  font-weight: 500;

  font-family: "Roboto Mono";

  /* justify-content: center; */
}
.sdkquery-content p {
  margin: 0;
}

.sdkquery-content1 {
  margin-top: 25px;
  margin-left: 1rem;
  font-size: 13px;
  padding: 1rem 1rem;
  font-family: "Roboto Mono";
  font-weight: 500;
  line-height: 20px;
  /* justify-content: center; */
}
.blue {
  color: #5200ff;
}
.purple {
  color: #006fbf;
}
.red {
  color: #ff185e;
}
p {
  margin: 0;
  padding: 0;
}
.redish {
  color: #8b1a10;
}
.blueish {
  color: #004aff;
}
.black {
  color: #232324;
}
pre {
  margin-top: -15px;
  display: inline;
}
.gray {
  color: #19b0bb;
}
.mt-5 {
  margin-top: 5rem;
}

.mb-2 {
  margin-bottom: 2rem;
  margin-top: -30px;
}

.mb-21 {
  margin-bottom: 2rem;
  margin-top: -28px;
  line-height: 20px;
}
.w-40 {
  width: 30%;
}

.w-80 {
  width: 70%;
  background-color: #f4f7fe;
  height: 100%;
  overflow-y: scroll;
}

.w-80::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.w-80::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  position: absolute;
  right: 1rem;
}
.w-80::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
  border-radius: 10px;
  padding-right: 50px;
}
.w-80:hover::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}
.flex-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.workflow-header {
  width: 100%;
  height: 28px;
  border-bottom: solid 1px #eef1f4;
}

.selected {
  background-color: #f4f7fe !important ;
}

.hide {
  display: none !important;
}
.img4 {
  height: 73px !important;
  margin-left: 60px;
  margin-bottom: 30px;
  width: 80%;
}
.img5 {
  height: 73px !important;
  margin-left: 60px;
  margin-bottom: 30px;
  width: 80%;
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  margin: auto;
  background-color: #f4f5f7;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid white;
  padding: 2rem;
}

.img11 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  margin: auto;
  background-color: #f4f5f7;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid white;
  padding: 2rem;
}

.img12 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  margin: auto;
  background-color: #f4f5f7;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid white;
  padding: 2rem;
}

.img13 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  margin: auto;
  background-color: #f4f5f7;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid white;
  padding: 2rem;
}

.img1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin: auto;
  background-color: #f4f5f7;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid white;
}

.workflow-setting-container img {
  height: 35rem;
  /* width: 80%; */
}
.plan-paragrah {
  height: 200px;
}
.main_div {
  height: 200px;
}
