.suggestModal {
  justify-content: center;
}

.Modal {
  background-color: white;
  width: 600px;
  height: 342px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.suggest-modal-body {
  background-color: white;
  width: 460px;
  height: 242px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 14px;
}
.suggest-modal-body:active,
.suggest-modal-body:focus-within {
  border: 1px solid #3f74f6;
}
.suggest-modal-body:focus-within button {
  opacity: 1;
}

.suggest-modal-body textarea {
  border: none;
  min-width: 392px;
  min-height: 80px;
  color: #0c4284;
  resize: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.suggest-modal-body textarea:focus-visible {
  outline: none;
}

.submit-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submit-btn button {
  background: #3f74f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border: 1px solid rgba(63, 116, 246, 0.3);
  border-radius: 6px;
  margin: 2.5px;
  color: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 7px;
  cursor: pointer;
  opacity: 0.5;
}
.submit-btn span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 13px;
  color: #3f74f6;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 7px;
  cursor: pointer;
}

.Modal > input {
  width: 460px;
  padding: 14px;
  outline: none;
  color: #0c4284;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  margin: 6px;
}
.Modal > input:focus-visible {
  outline: none;
  border: 1px solid #3f74f6;
}

#sib-container input:-ms-input-placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#sib-container input::placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#sib-container textarea::placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#error-message {
  font-size: 16px;
  text-align: left;
  font-family: Helvetica, sans-serif;
  color: #661d1d;
  background-color: #ffeded;
  border-radius: 3px;
  border: 1px solid #ff4949;
  max-width: 540px;
  width: 460px;
}

#success-message {
  font-size: 16px;
  text-align: left;
  font-family: Helvetica, sans-serif;
  color: #085229;
  background-color: #e7faf0;
  border-radius: 3px;
  border: 1px solid #13ce66;
  max-width: 540px;
  width: 460px;
}

.sib-icon {
  height: 1.5em;
  width: 1.5em;
  flex-shrink: 0;
  margin-right: calc(1rem - 1px);
}

.sib-form-message-panel_text {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
}

.sib-form-message-panel__text--center {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
}

.green {
  fill: #085229;
}

.red {
  fill: #661d1d;
}
.sib-form-message-panel {
  margin-bottom: 16px;
  transition: 0.5s;
}
