.option-item {
  height: 48px;
  background: white;
  border: 2px solid rgba(63, 116, 246, 0.3);
  border-radius: 6px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.option-item.selected {
  background: rgba(63, 116, 246, 0.08);
  border-color: #3f74f6;
}

.option-item .option-item-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(63, 116, 246, 0.3);
  margin-left: 18px;
  margin-right: 18px;
}

.option-item.selected .option-item-circle {
  background: #3f74f6;
}

.option-item .option-item-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(63, 116, 246, 0.3);
  margin-right: 18px;
}

.option-item.selected .option-item-text {
  color: #3f74f6;
}

.option-selector {
  margin-top: 0px;
}

.option-selector-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  color: #414d77;
}

.option-selector-wrapper {
  display: flex;
}
