.reset-password-modal-body {
  display: flex;
  height: 100%;
}

.reset-password-modal-body .reset-password-image {
  width: 100%;
  height: 100%;
}

.reset-password-modal-body .right-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 410px;
  font-family: "Inter";
}

.reset-password-modal-body .reset-password-avata {
  width: 62.31px;
  height: 60px;
  margin: 30px auto 10px auto;
}

.reset-password-modal-body .reset-password-caption1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
  color: #0c4284;
  margin-bottom: 15px;
}

.reset-password-modal-body .reset-password-caption2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
  color: #0c4284;
  margin-bottom: 25px;
  margin-top: 25px;
}

.reset-password-modal-body .reset-password-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgb(38, 37, 37);
  width: 312px;
  margin-top: 0px;
  margin-bottom: 35px;
}

.reset-password-modal-body form {
  display: flex;
  flex-direction: column;
}

.reset-password-modal-body input {
  width: 263px;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 18px;
  border: 1.6px solid rgba(63, 116, 246, 0.3);
  border-radius: 6px;
  margin-top: 10px;
}

.reset-password-modal-body input:focus,
.reset-password-modal-body input:hover {
  border-color: #3f74f6;
}

.reset-password-modal-body button {
  width: 263px;
  height: 48px;
  margin-top: 16px;
  border: none;
  border-radius: 6px;
  background: #3f74f6;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.reset-password-modal-body .reset-password-hyper-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #3f74f6;
  margin-top: 50px;
  cursor: pointer;
}

.reset-password-modal-body .error {
  font-size: 11px;
  color: #f02849;
  line-height: 10px;
  margin-left: 19px;
  width: 230px;
  margin-top: 5px;
}

.reset-password-modal-body input.required {
  border-color: #eba9b4;
  color: #eba9b4;
}

.reset-password-modal-body input:focus.required {
  border-color: #f02849;
  color: #f02849;
  outline: none;
}
