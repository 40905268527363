.pattern-mark {
  position: relative;
}

.pattern-mark svg {
  pointer-events: none;
  box-sizing: border-box;
}

.pattern-mark .pattern-count {
  pointer-events: none;
  color: #000000;
  position: absolute;
  top: 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 0px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.pattern-wrapper .pattern-mark:hover > svg > circle {
  fill: white;
}
