.unsupported-browser-modal.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.unsupported-browser-modal .modal-content-wrap {
  background-color: white;
  border-radius: 24px;
  left: 50%;
  padding: 9px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.unsupported-browser-modal .modal-content-wrap .modal-close-button {
  cursor: pointer;
  height: 14px;
  position: absolute;
  right: 23px;
  top: 24px;
  width: 14px;
}

.unsupported-browser-modal .modal-content-wrap .modal-content {
  align-items: center;
  background-color: rgba(223, 245, 249);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 40px 70px;
  max-height: 485px;
  max-width: 485px;
}

.unsupported-browser-modal .modal-content-wrap .modal-content .callout {
  color: #744a06;
  font-size: 36px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
  margin-top: 19px;
}

.unsupported-browser-modal .modal-content-wrap .modal-content .callout .dash {
  font-weight: 300;
}

.unsupported-browser-modal .modal-content-wrap .modal-content .instructions {
  line-height: 20px;
  text-align: center;
}

.unsupported-browser-modal
  .modal-content-wrap
  .modal-content
  .instructions
  .browser {
  font-weight: 600;
  text-decoration-line: underline;
}

.unsupported-browser-modal
  .modal-content-wrap
  .modal-content
  .instructions
  .supported-size {
  text-decoration-line: none;
}

.unsupported-browser-modal .modal-content-wrap .modal-content .opt-out {
  color: rgba(61, 96, 148);
  margin-top: 24px;
}

@media (max-width: 500px) {
  .unsupported-browser-modal .modal-content-wrap .modal-content {
    width: 315px;
    padding: 40px 0px;
  }
}
